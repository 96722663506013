import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { useState } from 'react'
import './index.css'

const ButtonComponent = (props: any): JSX.Element => {
  const {
    id,
    variant,
    color,
    disable,
    href,
    onClick,
    size,
    text,
    tooltip,
    width,
    startIcon,
    endIcon,
    data_testId,
    ...rest
  } = props ?? {}
  const [isLoading, setIsLoading] = useState(false)

  const muiDisabled = disable || isLoading
  const muiFullWidth: boolean | undefined = width === 'full' ? true : undefined
  const muiWidth: number | undefined = typeof width === 'number' ? width : undefined

  let children: JSX.Element
  const buttonStyles = {
    width: muiWidth,
    alignSelf: 'center',
    borderRadius: '30px',
    padding: '8px 30px', // Add rounded corners directly
    ...rest,
  }
  if (href) {
    children = (
      <Button
        id={id}
        size={size || 'large'}
        variant={variant || 'contained'}
        color={color || 'secondary'}
        href={href}
        fullWidth={muiFullWidth}
        sx={buttonStyles}
        startIcon={startIcon}
        endIcon={endIcon}
        data-testid={data_testId}
      >
        {text}
      </Button>
    )
  } else {
    children = (
      <Button
        id={id}
        type='submit'
        size={size || 'large'}
        variant={variant || 'contained'}
        color={muiDisabled ? 'primary' : color || 'secondary'}
        disabled={muiDisabled}
        onClick={async () => {
          setIsLoading(true)
          if (onClick) await onClick()
          setIsLoading(false)
        }}
        fullWidth={muiFullWidth}
        sx={buttonStyles}
        startIcon={startIcon}
        endIcon={endIcon}
        data-testid={data_testId}
      >
        {isLoading ? 'Loading...' : text}
      </Button>
    )
  }
  return tooltip ? (
    <Tooltip title={tooltip} followCursor>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  )
}

export default ButtonComponent
