import { Box } from '@mui/material'
import L, { Icon } from 'leaflet'
import 'leaflet-control-geocoder' // Ensure leaflet-control-geocoder is imported
import 'leaflet-control-geocoder/dist/Control.Geocoder.css'
import 'leaflet-control-geocoder/dist/Control.Geocoder.js'
import 'leaflet/dist/leaflet.css'
import React, { useRef } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { MarkerProps } from '../../utils/types'
import './index.css'
import { isWeb } from 'utils/constants'

interface MapComponentProps {
  centerMarker: MarkerProps
  markers: MarkerProps[]
  onAddressUpdate?: (lat: number, lng: number, newAddress: string) => void
  Height?: any
  openFull?: any
}
interface CustomMarkerProps {
  marker: MarkerProps
  mapRef: React.MutableRefObject<L.Map | null>
  onAddressUpdate?: (lat: number, lng: number, newAddress: string) => void
}

const CustomMarker: React.FC<CustomMarkerProps> = (props) => {
  const { marker, mapRef, onAddressUpdate } = props
  const { position, iconPath, iconSize, draggable, infoWindowContent } = marker

  const icon = new Icon({
    iconUrl: iconPath || '/default-icon.png',
    iconSize: [iconSize?.width || 40, iconSize?.height || 40],
  })

  const handleDragEnd = (event: L.DragEndEvent) => {
    if (draggable && event.target instanceof L.Marker && onAddressUpdate) {
      const latLng = event.target.getLatLng()

      // **Ensure geocoder is available before using it**
      const geocoder = (L.Control as any).Geocoder.nominatim()
      const map = mapRef.current
      if (map) {
        const crsScale = map.options.crs?.scale(map.getZoom() || 0)
        geocoder.reverse(latLng, crsScale, (results: any) => {
          const result = results[0]
          if (result) {
            onAddressUpdate(latLng.lat, latLng.lng, result.name)
          }
        })
      }
    }
  }

  // Ensure infoWindowContent is a string to avoid TypeScript errors
  const content = typeof infoWindowContent === 'string' ? infoWindowContent : ''

  return (
    <Marker
      position={[position.lat, position.lng]}
      icon={icon}
      draggable={draggable}
      eventHandlers={{ dragend: handleDragEnd }}
    >
      <Popup>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Popup>
    </Marker>
  )
}

const MapComponent: React.FC<MapComponentProps> = (props) => {
  const { centerMarker, markers, onAddressUpdate, Height, openFull } = props
  const mapRef = useRef<L.Map | null>(null)
  const defaultZoom: number = 12,
    maxZoom: number = 15

  return (
    <Box sx={{ height: Height, transition: 'height 0.2s ease' }}>
      {!openFull && (
        <MapContainer
          center={[centerMarker.position.lat, centerMarker.position.lng]}
          zoom={defaultZoom}
          maxZoom={maxZoom}
          ref={mapRef}
          style={{ height: '450px', zIndex: 1, transition: 'height 0.6s ease' }}
          zoomControl={isWeb ? true : false} // Disable zoom controls on mobile since we have pinch and zoom
        >
          <TileLayer
            url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          />
          {markers.concat(centerMarker).map((marker: MarkerProps, i: number) => (
            <CustomMarker key={i} marker={marker} onAddressUpdate={onAddressUpdate} mapRef={mapRef} />
          ))}
        </MapContainer>
      )}
      {openFull && (
        <MapContainer
          center={[centerMarker.position.lat, centerMarker.position.lng]}
          zoom={defaultZoom}
          maxZoom={maxZoom}
          ref={mapRef}
          style={{ height: '95vh', zIndex: 1, transition: 'height 0.6s ease' }}
        >
          <TileLayer
            url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          />
          {markers.concat(centerMarker).map((marker: MarkerProps, i: number) => (
            <CustomMarker key={i} marker={marker} onAddressUpdate={onAddressUpdate} mapRef={mapRef} />
          ))}
        </MapContainer>
      )}
    </Box>
  )
}

export default MapComponent
