import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import { PaperFlowComponent } from 'components/PaperFlowComponent'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import InputComponent from 'components/input'
import NavComponent from 'components/nav'
import TextComponent from 'components/text'
import ToastComponent from 'components/toast'
import { parsePhoneNumber } from 'libphonenumber-js/max'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { baseURL, passwordTooltip, reenterPasswordTooltip } from 'utils/constants'
import { MessageProps } from 'utils/types'
import { setUserPassword } from 'utils/users'
import { isMatchingPassword, isValidPassword, isValidPhone, isValidRelationship } from 'utils/validations'
import './index.css'
import SelectComponent from 'components/select'
import { enabledRelationships } from 'utils/students'

function Index(): JSX.Element {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const pn: string = params?.pn ?? ''
  const tp: string = searchParams.get('tp') ?? ''
  const navigate: NavigateFunction = useNavigate()

  // user states
  const [vars, setVars] = useState<any>({
    userStudentRelationship: '',
    userPassword: '',
    userReenteredPassword: '',
    isConsent: false,
  })
  const [msg, setMsg] = useState<MessageProps>()
  const { userStudentRelationship, userPassword, userReenteredPassword, isConsent } = vars

  useEffect(() => {
    (() => {
      if (!isValidPhone(pn, true) || !isValidPassword(tp, true)) navigate('/')
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isValid: boolean =
    isValidRelationship(userStudentRelationship, true) &&
    isValidPassword(userPassword, true) &&
    isMatchingPassword(userReenteredPassword, userPassword, true) &&
    isConsent
  const onClose = () => navigate(-1)

  return (
    <CenterComponent onClose={onClose}>
      <PaperFlowComponent
        title={`Activate Your Account`}
        subtitle={`Set a password to complete your account activation. Your username is your phone number: ${parsePhoneNumber(
          pn ?? '',
        ).formatNational()}`}
      />
      <SelectComponent
        label='Relationship'
        data_testid='relationship_testid'
        isRequired={true}
        isError={!isValidRelationship(userStudentRelationship)}
        options={enabledRelationships}
        value={userStudentRelationship}
        onChange={(v: string) => setVars({ ...vars, userStudentRelationship: v })}
      />
      <InputComponent
        type='password'
        label='Password'
        isRequired={true}
        isError={!isValidPassword(userPassword)}
        helperText={!isValidPassword(userPassword) ? passwordTooltip() : ''}
        onChange={(v: string) => {
          setVars({ ...vars, userPassword: v })
        }}
      />
      <InputComponent
        type='password'
        label='Reenter Password'
        isRequired={true}
        isError={!isMatchingPassword(userReenteredPassword, userPassword)}
        helperText={!isMatchingPassword(userReenteredPassword, userPassword) ? reenterPasswordTooltip() : ''}
        onChange={(v: string) => {
          setVars({ ...vars, userReenteredPassword: v })
        }}
      />
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
        <Checkbox
          size='small'
          color='warning' // Update checkbox state
          checked={isConsent ? true : false}
          onChange={(v: any) => setVars({ ...vars, isConsent: v.target.checked })}
        />
        <Box mt={1}>
          <TextComponent size='body2'>
            I agree to the Carpool School Inc.{' '}
            <span style={{ color: '#beac48', fontWeight: 'bold', textDecoration: 'underline' }}>
              <NavComponent
                href={`${baseURL}/home/index.html#eula`}
                text='End User License Agreement'
                isOpenNewTab={true}
              />
            </span>
            .
          </TextComponent>
        </Box>
      </Box>
      <ButtonComponent
        text='Activate'
        disable={!isValid}
        onClick={async () => {
          const { msg, error } = await setUserPassword(pn, tp, userPassword)
          if (error) setMsg(msg)
          else {
            navigate(`/signin`)
          }
        }}
      />
      <Box pt={1} fontSize={14}>
        <>
          CARPOOL.SCHOOL DOES NOT VERIFY DRIVERS{"'"} LICENSES OR INSURANCE COVERAGE. USERS ARE RESPONSIBLE FOR
          SCREENING ALL PARTICIPANTS OF CARPOOL.
        </>
      </Box>
      {msg ? (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      ) : (
        <></>
      )}
    </CenterComponent>
  )
}

export default Index
