import { PaperFlowComponent } from 'components/PaperFlowComponent'
import CenterComponent from 'components/center'
import ToastComponent from 'components/toast'
import TypeaheadComponent from 'components/typeahead'
import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { getSchool } from 'utils/schools'
import { MessageProps } from 'utils/types'
import './index.css'

function Index(): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // states
  const [msg, setMsg] = useState<MessageProps>()

  // handlers
  const onClose = () => navigate('/')
  const onBack = () => navigate(-1)
  const onSubmit = (sn: string) => navigate(`/add/verifySchool?sn=${sn}`)

  return (
    <CenterComponent onClose={onClose} onBack={onBack} spacing={2}>
      <PaperFlowComponent title={'New School Registration'} subtitle={`Find your school?`} />
      <TypeaheadComponent
        onChange={async (v: any) => {
          if (!v || !v.communityName || !v.communityId) return
          let schoolId: string = `${v.communityName}-${v.communityId}`
          const { msg, data, error } = await getSchool(schoolId)
          if (error) setMsg(msg)
          else if (data?.status === 'missing') schoolId = v.communityName
          onSubmit(schoolId)
        }}
      />
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </CenterComponent>
  )
}

export default Index
