import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Dialog, DialogTitle } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import InputComponent from 'components/input'
import PaperComponent from 'components/paper'
import SelectComponent from 'components/select'
import CircularProgressWithLabel from 'components/table/CircularProgressLabel'
import ToastComponent from 'components/toast'
import { parsePhoneNumber } from 'libphonenumber-js/max'
import React, { useEffect, useState } from 'react'
import { parseJwt } from 'utils/constants'
import { enabledRelationships, updateStudentSettings } from 'utils/students'
import { MessageProps, StudentProps, UserProps } from 'utils/types'
import { getUser, updateUser } from 'utils/users'
import { isValidName, isValidRelationship } from 'utils/validations'
import './index.css'

interface Props {
  tokens: any
  students: StudentProps[]
  user?: Pick<UserProps, 'relation'>
  onUpdate: (cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  // Inputs: Tokens, Students, User Relation, and Callback
  const { tokens, students, user: u, onUpdate } = props ?? {}
  const { IdToken } = tokens
  const phoneNumber: string = parseJwt(IdToken).phone_number

  // states
  const [userFirstName, setUserFirstName] = useState<string>('')
  const [userLastName, setUserLastName] = useState<string>('')
  const [userStudentRelationship, setUserStudentRelationship] = useState<string>(u?.relation ?? '')
  const [msg, setMsg] = useState<MessageProps>()
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<string>('')
  const handleNameUpdate = (name?: string) => {
    // Split the full name into parts based on whitespace
    const parts = name?.trim().split(/\s+/) ?? []
    // Extract the last part as lastName
    const lastName = parts.pop() || ''
    // Join the remaining parts as firstName
    const firstName = parts.join(' ')
    setUserFirstName(firstName)
    setUserLastName(lastName)
  }

  // hooks
  useEffect(() => {
    const loadUserData = async () => {
      const { data, msg, error } = await getUser(phoneNumber)
      if (error) setMsg(msg)
      else {
        const u: UserProps = data?.user as UserProps
        handleNameUpdate(u?.name)
      }
      setIsFirstLoad(false)
    }
    loadUserData()
  }, [phoneNumber])

  if (isFirstLoad) return <LinearProgress />
  const isProfileValid: boolean = isValidName(userFirstName, true) && isValidName(userLastName, true)

  return (
    <CenterComponent marginTop={0}>
      {/***************** User Profile *****************/}
      <PaperComponent title={'User Profile'}>
        <Box display='flex' flexDirection='column' justifyContent='center' paddingTop={2} gap={2}>
          <InputComponent
            type='text'
            label='First Name'
            data_testid='first_name_testid'
            isRequired={true}
            isError={!isValidName(userFirstName)}
            value={userFirstName}
            onChange={(v: string) => setUserFirstName(v)}
          />
          <InputComponent
            type='text'
            label='Last Name'
            data_testid='last_name_testid'
            isRequired={true}
            isError={!isValidName(userLastName)}
            value={userLastName}
            onChange={(v: string) => setUserLastName(v)}
          />
          {userStudentRelationship && (
            <SelectComponent
              label='Relationship'
              data_testid='relationship_testid'
              isRequired={true}
              isError={!isValidRelationship(userStudentRelationship)}
              options={enabledRelationships}
              value={userStudentRelationship}
              onChange={(v: string) => setUserStudentRelationship(v)}
            />
          )}
          <InputComponent
            type='tel'
            label='Phone number of adult'
            isDisabled={true}
            defaultValue={parsePhoneNumber(phoneNumber).formatNational()}
          />
          <ButtonComponent
            text='Save'
            disable={!isProfileValid}
            endIcon={<ArrowForwardIcon />}
            onClick={async () => {
              setIsLoading('Updating user profile...')
              try {
                handleNameUpdate(`${userFirstName} ${userLastName}`)
                // Update User Name
                const res1 = await updateUser(phoneNumber, `${userFirstName} ${userLastName}`)
                if (res1?.error) throw new Error('User name update failed')

                // Update User Student Relationships
                const res2 = await Promise.all(
                  students.map(async (s: StudentProps) => {
                    const { schoolName, studentId } = s
                    return await updateStudentSettings(schoolName, studentId, userStudentRelationship)
                  }),
                )
                res2.forEach((v: any) => {
                  if (v?.error) throw new Error('User relationship update failed')
                })
              } catch (error) {
                setMsg({
                  style: 'error',
                  heading: 'Oops, Something Went Wrong',
                  text: `We encountered an issue while processing your request. Please try your operation again. If the issue persists, please don't hesitate to contact our support team at support@carpool.school. We'll be happy to assist you further. Thank you for your understanding.`,
                })
              }
              setIsLoading('')
              // Trigger to load user data from backend so we can recompute first and last names from full name
              onUpdate()
            }}
          />
        </Box>
      </PaperComponent>

      {/***************** Display progress *****************/}
      {isLoading && (
        <Dialog open={true}>
          <DialogTitle>{isLoading}</DialogTitle>
          <CircularProgressWithLabel />
        </Dialog>
      )}

      {/***************** Display messages *****************/}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </CenterComponent>
  )
}

export default Index
