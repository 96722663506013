import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Alert, Box, Dialog, DialogTitle, IconButton, LinearProgress, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import ButtonComponent from 'components/button'
import { CalendarComponent } from 'components/calendar'
import CenterComponent from 'components/center'
import InputComponent from 'components/input'
import ModalComponent from 'components/modal'
import PaperComponent from 'components/paper'
import PlacesAutocompleteComponent from 'components/places-autocomplete'
import SelectComponent from 'components/select'
import CircularProgressWithLabel from 'components/table/CircularProgressLabel'
import TagComponent from 'components/tag'
import ToastComponent from 'components/toast'
import { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { getFullDate, getTimeNumber, getTimeString, getTimeStringFromNumber } from 'utils/constants'
import { geocode } from 'utils/map'
import { updateStudentAfternoonScheduleSettings, updateStudentAfternoonSettings } from 'utils/students'
import {
  CalendarEventProps,
  CalendarProps,
  DateTimeProps,
  LocationProps,
  MessageProps,
  ScheduleGroupProps,
  StudentProps,
} from 'utils/types'
import { isValidComments } from 'utils/validations'
import './index.css'

const AfternoonComponent = (props: any) => {
  const theme = useTheme()
  const navigate: NavigateFunction = useNavigate()

  // Inputs: School Name, Location, Schedule End Time, Student Drop off Location, Activities, Schedule and onUpdate Callbck
  const { student, onUpdate } = props
  const {
    schoolName,
    schoolLocation,
    schoolScheduleGroups,
    studentId,
    scheduleGroupName,
    dropoffLocation,
    dropoffTimes,
  } = student
  const isNeedPMPool: boolean = dropoffLocation && dropoffLocation?.lat && dropoffLocation?.lng ? true : false
  const schoolStartDate: string = new Date().toISOString() // Initialize start to current date.
  const schoolEndDate: string = new Date(new Date().getFullYear() + 1, 11, 31).toISOString() // Initialize end to end of next year.
  const schoolEndTime: number =
    schoolScheduleGroups?.find((v: ScheduleGroupProps) => v?.schoolScheduleGroupName === scheduleGroupName)
      ?.schoolScheduleGroupEndTime ?? 0 // Initialize selected schedule dismissal time.
  const schoolActivities = student?.schoolActivities?.sort() ?? []
  const { lat, lng } = schoolLocation
  const bounds = [lng - 0.7, lat - 0.7, lng + 0.7, lat + 0.7]

  // states
  const [studentDropoffLocation, setStudentDropoffLocation] = useState<LocationProps>({
    address: dropoffLocation?.address || '',
  })
  const [dropoffSeatsAvailable, setDropoffSeatsAvailable] = useState<number>(student?.dropoffSeatsAvailable)
  const [dropoffComments, setDropoffComments] = useState<string>(student?.dropoffComments)
  const [studentActivities, setStudentActivities] = useState<string[]>(
    student?.studentActivities?.filter((v: string) => schoolActivities?.includes(v)) ?? [],
  )
  const [dropoffDateTime, setDropoffDateTime] = useState<DateTimeProps>(dropoffTimes)
  const [delOpen, setDelOpen] = useState(false)
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')

  // handlers
  const onSubmit = async (): Promise<any> => {
    let dropoffLocation: LocationProps = studentDropoffLocation
    if (dropoffLocation.address && (!dropoffLocation?.lat || !dropoffLocation?.lng)) {
      dropoffLocation = await geocode(dropoffLocation.address)
      if (!dropoffLocation?.lat || !dropoffLocation?.lng) {
        setMsg({
          style: 'error',
          text: 'We could not locate this address. Verify and re-enter address.',
        })
        setIsLoading('')
        return
      }
    }
    const { msg, error } = await updateStudentAfternoonSettings(
      schoolName,
      studentId,
      dropoffLocation,
      dropoffSeatsAvailable,
      dropoffComments,
      studentActivities,
    )
    if (error) setMsg(msg)
    else onUpdate(() => navigate('/'))
    setStudentDropoffLocation(dropoffLocation)
    setIsLoading('')
  }
  const events: CalendarEventProps[] = [],
    defaultEvents: CalendarEventProps[] = []
  const toCalendarEvents = (defaultDateTime: DateTimeProps, dateTime: DateTimeProps) => {
    let loop = new Date(new Date(schoolStartDate))
    do {
      const d: string = getFullDate(loop),
        dt: Date = new Date(`${d}T${getTimeStringFromNumber(schoolEndTime)}`)
      const isWeekday = (): boolean => dt.getDay() !== 0 && dt.getDay() !== 6
      let defaultEvent: CalendarEventProps = { from: dt },
        event: CalendarEventProps | undefined
      if (defaultDateTime[d])
        defaultEvent = event = {
          from: new Date(`${d}T${getTimeStringFromNumber(defaultDateTime[d])}`),
          ...(defaultDateTime[d] === -1 && { eventState: 'HOLIDAY' }),
        }
      if (dateTime[d])
        event = {
          from: new Date(`${d}T${getTimeStringFromNumber(dateTime[d])}`),
          ...(dateTime[d] === -1 && { eventState: 'DELETED' }),
        }
      if (isWeekday()) {
        defaultEvents.push(defaultEvent)
        if (event) events.push(event)
      }
      loop = new Date(loop.setDate(loop.getDate() + 1))
    } while (loop <= new Date(schoolEndDate))
  }
  toCalendarEvents({}, dropoffDateTime ?? {})
  // Function to close delete confirmation dialog
  const handleDelClose = () => {
    setDelOpen(false)
  }
  // Function to open dialog for delete confirmation
  const handleDelClick = () => {
    setDelOpen(true)
  }
  // Function to handle delete submit
  const handleDelSubmit = async () => {
    setDelOpen(false)
    setIsLoading('Deleting afternoon carpool request')
    const dropoffLocation: LocationProps = { address: '' }
    const { msg, error } = await updateStudentAfternoonSettings(schoolName, studentId, dropoffLocation)
    if (error) setMsg(msg)
    else onUpdate(() => navigate('/'))
    setStudentDropoffLocation(dropoffLocation)
    setIsLoading('')
  }

  const isValidAddress: boolean = studentDropoffLocation.address ? true : false
  const isValid: boolean = isValidAddress && isValidComments(dropoffComments)

  return (
    <CenterComponent marginTop={0}>
      <PaperComponent title={'Afternoon Carpool'}>
        <PaperComponent subtitle={'Dropoff Location'}>
          <Grid container>
            <Grid item xs>
              <PlacesAutocompleteComponent
                isRequired={true}
                label='Select Address / Landmark'
                defaultValue={studentDropoffLocation.address}
                dataTestid='dropoff-afternoon-testid'
                bounds={bounds}
                onChange={(v: string) => setStudentDropoffLocation({ address: v })}
                onSubmit={(v: LocationProps) => setStudentDropoffLocation(v)}
              />
            </Grid>
            {isNeedPMPool && (
              <>
                <IconButton aria-label='delete' color='error' onClick={handleDelClick} title='Remove Afternoon Carpool'>
                  <DeleteOutlinedIcon sx={{ fontSize: 22 }} data-testid='DeleteAfternoonCarpoolIcon' />
                </IconButton>
                <ModalComponent
                  isShow={delOpen}
                  title={`Remove Afternoon Carpool?`}
                  body={
                    'Removing the dropoff address will also cancel your afternoon carpool enrollment. You will no longer see other families needing afternoon carpool.'
                  }
                  onActionCancel={handleDelClose}
                  onActionSubmit={handleDelSubmit}
                  submitActionType='Delete'
                />
              </>
            )}
          </Grid>
        </PaperComponent>
        <PaperComponent subtitle={'Available Seats'}>
          <SelectComponent
            label='Select seats'
            options={[1, 2, 3, 4, 5, 6, 7]}
            value={dropoffSeatsAvailable ?? ''}
            onChange={(v: number) => setDropoffSeatsAvailable(v)}
          />
        </PaperComponent>
        <PaperComponent subtitle={'Comments'}>
          <InputComponent
            type='text'
            rows={4}
            label='Comments'
            placeholder='Enter details that might help with carpooling arrangements'
            helperText={`Share any relevant info about your vehcile, vanpool interests, schedule, carpool preferences, or special needs.`}
            isRequired={false}
            isError={!isValidComments(dropoffComments)}
            value={dropoffComments}
            onChange={(v: string) => setDropoffComments(v)}
          />
        </PaperComponent>
        <PaperComponent subtitle={'Activities / Tags'}>
          <TagComponent
            label='Set activities or event tags'
            options={schoolActivities ?? []}
            selected={studentActivities ?? []}
            allowMultiple={true}
            helperText='Contact school admin for missing activities/tags.'
            onChange={(v: string[]) => setStudentActivities(v)}
          />
        </PaperComponent>
        <PaperComponent subtitle={'Tap a date & change schedule'}>
          <CalendarComponent
            events={events}
            defaultEvents={defaultEvents}
            onChange={async (event: CalendarProps) => {
              const d: string = getFullDate(event.from)
              const t: number = event.userAction === 'DELETE' ? -1 : getTimeNumber(event.from)
              const { msg, error } = await updateStudentAfternoonScheduleSettings(schoolName, studentId, { [d]: t })
              if (error) setMsg(msg)
              else {
                setDropoffDateTime({ ...dropoffDateTime, [d]: t })
                await onUpdate()
              }
            }}
          />
          <Alert color='warning' severity='info' variant='standard'>
            Default school dismissal time is{' '}
            <Box component='span' sx={{ color: theme.palette.warning.main, fontWeight: 'bold' }}>
              {getTimeString(schoolEndTime)}
            </Box>
          </Alert>
        </PaperComponent>
        <ButtonComponent
          text='Submit'
          width='full'
          disable={!isValid}
          endIcon={<ArrowForwardIcon />}
          onClick={onSubmit}
        />
      </PaperComponent>

      {/***************** Display progress *****************/}
      {isLoading && (
        <Dialog open={true}>
          <DialogTitle>{isLoading}</DialogTitle>
          <CircularProgressWithLabel />
        </Dialog>
      )}

      {/***************** Display messages *****************/}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </CenterComponent>
  )
}

interface Props {
  students: StudentProps[]
  processQuery: (id: string) => string
  onUpdate: (cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()

  // Inputs: Selected Student Id, and Callback
  const { students, processQuery, onUpdate } = props

  // We should never call this component without student id query search parameters, but this is just to be safe
  const studentIdFromQuery = searchParams.get('id') ?? ''
  useEffect(() => {
    setSearchParams({ id: processQuery(studentIdFromQuery) })
  }, [studentIdFromQuery, processQuery, setSearchParams])

  // Build props for child component
  const student: StudentProps | undefined = students?.find((s: StudentProps) => s.studentId === studentIdFromQuery)

  if (!student) return <LinearProgress />
  return <AfternoonComponent student={student} onUpdate={onUpdate} />
}

export default Index
