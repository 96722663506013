import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Box from '@mui/material/Box'
import { PaperFlowComponent } from 'components/PaperFlowComponent'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import InputComponent from 'components/input'
import TextComponent from 'components/text'
import ToastComponent from 'components/toast'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { setSessionItem } from 'utils/constants'
import { getSchool, verifySchoolCode } from 'utils/schools'
import { MessageProps } from 'utils/types'

import './index.css'
import LinearProgress from '@mui/material/LinearProgress'

function Index(): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: School Name
  const [searchParams] = useSearchParams()
  const schoolName: string = searchParams.get('sn') || ''

  // states
  const [schoolAdmins, setSchoolAdmins] = useState<string[]>()
  const [schoolScheduleGroupNames, setSchoolScheduleGroupNames] = useState<string[]>([])
  const [schoolStatus, setSchoolStatus] = useState<string>('')
  const [schoolCode, setSchoolCode] = useState<string>('')
  const [msg, setMsg] = useState<MessageProps>()

  // hooks
  useEffect(() => {
    (async () => {
      const { msg, data, error } = await getSchool(schoolName)
      if (error) setMsg(msg)
      else if (data) {
        setSchoolStatus(data.status as string)
        setSchoolAdmins(data?.schoolAdmins as string[])
        setSchoolScheduleGroupNames(
          (data?.schoolScheduleGroups as any[])?.map((group: any) => group.schoolScheduleGroupName),
        )
      }
    })()
  }, [schoolName])

  // handlers
  const onClose = () => navigate('/')
  const onBack = () => navigate(-1)
  const onNext = async (): Promise<any> => {
    const { msg, error } = await verifySchoolCode(schoolName, schoolCode)
    if (error) setMsg(msg)
    else {
      // Save school name and code for following steps
      setSessionItem('x-pool-sn', schoolName)
      setSessionItem('x-pool-sa', schoolAdmins)
      setSessionItem('x-pool-sc', schoolCode)
      setSessionItem('x-pool-ssgn', schoolScheduleGroupNames)
      if (schoolScheduleGroupNames.length > 1) {
        navigate('/signup/school/schedule')
      } else {
        setSessionItem('x-pool-sg', schoolScheduleGroupNames[0])
        navigate('/signup/createUser')
      }
    }
  }
  if (schoolStatus === 'verify_code') {
    return (
      <CenterComponent onClose={onClose} onBack={onBack} spacing={1}>
        <PaperFlowComponent title={'Verify Your Enrollment'} subtitle={`Enter your school code`} />
        <InputComponent
          type='text'
          placeholder='Enter school code'
          label='Verification code'
          data_testid='school_verification_code_testid'
          onChange={(v: string) => setSchoolCode(v.trimEnd())}
        />
        {/* Determine next route based on school and user input states */}
        <ButtonComponent
          text='Next'
          disable={!schoolCode}
          data_testId='next-btn'
          endIcon={<ArrowForwardIcon />}
          onClick={() => onNext()}
        />
        {schoolAdmins && schoolAdmins?.length >= 1 && (
          <Box pt={3}>
            <TextComponent size='body2'>
              No school code? Contact your school admin{` at ${schoolAdmins[0].toString()}`}
            </TextComponent>
          </Box>
        )}
        {msg && (
          <ToastComponent
            style={msg?.style}
            heading={msg?.heading}
            text={msg?.text}
            onClose={() => setMsg(undefined)}
          />
        )}
      </CenterComponent>
    )
  } else if (schoolStatus === 'verify_manual') {
    return (
      <CenterComponent onClose={onClose} onBack={onBack} spacing={1}>
        <PaperFlowComponent
          title={'Self-Registration Not Available'}
          subtitle={`Please contact your school administrator to complete the registration process.`}
        />
      </CenterComponent>
    )
  } else if (schoolStatus === 'disabled') {
    return (
      <CenterComponent onClose={onClose} onBack={onBack} spacing={1}>
        <PaperFlowComponent
          title={'School not Enrolled'}
          subtitle={`Ask school/pta to enroll for carpool.school service.`}
        />
      </CenterComponent>
    )
  } else if (schoolStatus === 'missing') {
    return (
      <CenterComponent onClose={onClose} onBack={onBack} spacing={1}>
        <PaperFlowComponent
          title={'School Missing'}
          subtitle={`Contact us at support@carpool.school to add your school.`}
        />
      </CenterComponent>
    )
  } else return <LinearProgress />
}

export default Index
