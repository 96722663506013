import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { PieChart } from '@mui/x-charts/PieChart'
import './index.css'

const PieChartComponent = (props: any) => {
  const { title, data, height } = props
  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ p: 1 }}>
      <Card raised>
        <CardContent>
          <Typography color='text.secondary'>{title}</Typography>
          <PieChart
            series={[
              {
                innerRadius: 20,
                paddingAngle: 1,
                data,
                arcLabel: (v) => `${v.value}`,
              },
            ]}
            height={height}
            slotProps={{
              legend: {
                direction: 'column',
                position: { vertical: 'middle', horizontal: 'right' },
                itemMarkWidth: 10,
                itemMarkHeight: 5,
              },
            }}
          />
        </CardContent>
      </Card>
    </Grid>
  )
}

export default PieChartComponent
