import {
  GetPlaceCommand,
  GetPlaceCommandOutput,
  SearchForSuggestionsResult,
  SearchPlaceIndexForSuggestionsCommand,
  SearchPlaceIndexForSuggestionsCommandOutput,
} from '@aws-sdk/client-location'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import { ChangeEvent, useCallback, useState } from 'react'
import { getAwsLocationClient, replaceCountryWithCode } from 'utils/map'
import { LocationProps } from 'utils/types'
import './index.css'

// Define the props for the component
interface Suggestion {
  Text: string
  PlaceId: string
}
interface PlacesAutocompleteProps {
  isRequired?: boolean
  isDisabled?: boolean
  type?: string
  label: string
  tooltip?: any
  defaultValue?: string
  bounds?: number[]
  onChange: (address: string) => void
  onSubmit: (location: LocationProps) => void
  isError?: boolean
  isSuccess?: boolean
  helperText?: string
  dataTestid?: string
  placeholder?: string
  width?: string
}

const PlacesAutocompleteComponent = (props: PlacesAutocompleteProps): JSX.Element => {
  const {
    isRequired,
    isDisabled,
    type,
    label,
    tooltip,
    defaultValue,
    bounds,
    onChange,
    onSubmit,
    isError,
    isSuccess,
    helperText,
    dataTestid,
    placeholder,
    width,
  } = props ?? {}
  const [open, setOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [inputValue, setInputValue] = useState<string>(defaultValue ?? '')
  const [suggestions, setSuggestions] = useState<Suggestion[]>([])

  const handleClickAway = () => {
    setOpen(false)
  }

  // hooks
  /* eslint @typescript-eslint/no-var-requires: "off" */
  const debounce = require('lodash/debounce')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchSuggestions = useCallback(
    debounce(async (Text: string) => {
      const options: Suggestion[] = []
      if (Text.length > 2) {
        try {
          const awsLocationClient = await getAwsLocationClient()
          const data: SearchPlaceIndexForSuggestionsCommandOutput = await awsLocationClient.send(
            new SearchPlaceIndexForSuggestionsCommand({
              IndexName: process.env.REACT_APP_AWS_PLACE_INDEX,
              Text,
              FilterBBox: bounds,
              MaxResults: 5,
            }),
          )
          data?.Results?.forEach((v: SearchForSuggestionsResult) => {
            if (v.Text && v.PlaceId) options.push({ Text: v.Text, PlaceId: v.PlaceId })
          })
        } catch (error) {
          console.error('Error fetching suggestions:', error)
        }
      }
      setSuggestions(options)
    }, 300), // Debounce delay in milliseconds
    [],
  )

  // handlers
  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value
    setInputValue(text)
    onChange(text)
    debouncedFetchSuggestions(text)
  }
  const handleSelectSuggestion = async (placeId: string) => {
    try {
      const awsLocationClient = await getAwsLocationClient()
      const data: GetPlaceCommandOutput = await awsLocationClient.send(
        new GetPlaceCommand({
          IndexName: process.env.REACT_APP_AWS_PLACE_INDEX,
          PlaceId: placeId,
        }),
      )
      if (
        data?.Place &&
        data.Place.Label &&
        data?.Place?.Geometry &&
        data.Place.Geometry.Point?.length &&
        data.Place.Geometry.Point?.length >= 2
      ) {
        const address: string = replaceCountryWithCode(data.Place.Label)
        const result: LocationProps = {
          address,
          lat: data.Place.Geometry.Point[1],
          lng: data.Place.Geometry.Point[0],
          placeId,
        }
        setInputValue(address)
        setSuggestions([])
        onSubmit(result)
      } else throw new Error('Unexpected error')
    } catch (error) {
      console.error('Error fetching suggestions:', error)
    }
  }

  const children: JSX.Element = (
    <ClickAwayListener onClickAway={handleClickAway}>
      <center>
        <TextField
          required={isRequired}
          disabled={isDisabled}
          type={type}
          label={label}
          value={inputValue}
          onClick={(event) => {
            setAnchorEl(event.currentTarget)
            setOpen(true)
          }}
          onChange={handleInputChange}
          error={isError}
          helperText={helperText}
          fullWidth={true}
          color={isError ? 'error' : isSuccess ? 'success' : 'primary'}
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '0.8125rem', // Adjust font size as needed
            },
            textAlign: 'left',
            borderRadius: '30px',
            width: width ?? '100%',
            '& .MuiOutlinedInput-root': { borderRadius: '30px' },
          }}
          margin='dense'
          size='small'
          data-testid={dataTestid}
          placeholder={placeholder}
        />
        {open && anchorEl && suggestions?.length > 0 && (
          <Paper
            style={{
              position: 'absolute',
              top: anchorEl.getBoundingClientRect().bottom,
              left: anchorEl.getBoundingClientRect().left,
              width: anchorEl.getBoundingClientRect().width,
              zIndex: 1300, // Ensure it appears above other content
            }}
          >
            <List dense>
              {suggestions.map((suggestion: Suggestion, index: number) => (
                <ListItemButton key={index} onClick={() => handleSelectSuggestion(suggestion.PlaceId)}>
                  <InputAdornment position='start'>
                    <LocationOnIcon />
                  </InputAdornment>
                  <ListItemText primary={suggestion.Text} />
                </ListItemButton>
              ))}
            </List>
          </Paper>
        )}
      </center>
    </ClickAwayListener>
  )

  return tooltip ? (
    <Tooltip title={tooltip} followCursor disableHoverListener>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  )
}

export default PlacesAutocompleteComponent
