import React, { useEffect, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import SearchIcon from '@mui/icons-material/Search'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { baseURL, titleCase } from 'utils/constants'

const TypeaheadComponent = (props: any) => {
  const optionsUrl = `${baseURL}/api/communities?communityType=school&communityName=`
  const optionsKey = 'communities'
  const labelKey = (v: any) => `${v?.communityName}, ${v?.locality}, ${v?.adminArea}, ${v?.postalCode} ${v?.country}`
  const { onChange } = props
  const [value, setValue] = useState<any | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<readonly any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    setIsLoading(true)
    const getData = setTimeout(() => {
      fetch(`${optionsUrl}${encodeURIComponent(inputValue)}`)
        .then((resp) => resp.json())
        .then((json) => {
          let newOptions: readonly any[] = []
          if (value) newOptions = [value]
          if (json) newOptions = [...newOptions, ...json[optionsKey]]
          setOptions(newOptions)
        })
        .catch((error) => {
          return
        })
        .finally(() => setIsLoading(false))
    }, 400)

    return () => clearTimeout(getData)
  }, [value, inputValue, optionsUrl, optionsKey])

  // Custom Paper component with rounded corners
  const CustomPaper = (props: any) => <Paper {...props} sx={{ borderRadius: '30px' }} />

  return (
    <center>
      <Autocomplete
        getOptionLabel={labelKey}
        filterOptions={(x) => x}
        options={options}
        data-testid='school-name-dropdown'
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        loading={isLoading}
        sx={{ width: '95%', '& .MuiAutocomplete-inputRoot': { borderRadius: '30px' } }}
        loadingText={inputValue ? 'Loading schools...' : 'Schools starting with: None entered.'}
        noOptionsText={inputValue ? 'No matches found.' : 'Schools starting with: None entered.'}
        onChange={(event: any, newValue: any | null) => {
          setOptions(newValue ? [newValue, ...options] : options)
          setValue(newValue)
          onChange(newValue)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        PaperComponent={CustomPaper}
        renderInput={(params) => (
          <TextField
            {...params}
            label='School Name'
            placeholder='Begin typing your school name...'
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          const { communityId, communityName, locality, adminArea, postalCode, country } = option

          return (
            <li {...props} key={communityId}>
              <Grid container alignItems='center'>
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  <Typography variant='body2'>{titleCase(communityName)}</Typography>
                  <Typography variant='body2' color='text.secondary'>
                    {`${locality.toUpperCase()}, ${adminArea.toUpperCase()}, ${postalCode} ${country}`}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          )
        }}
      />
    </center>
  )
}

export default TypeaheadComponent
