import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import './index.css'

const CheckboxComponent = (props: any): JSX.Element => {
  const { disable, isChecked, label, onChange, justifyContent, color } = props ?? {}
  const children: JSX.Element = (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox disabled={disable ? true : false} checked={isChecked} onChange={onChange} color={color} />}
        label={label ?? ''}
        sx={{ display: 'flex', justifyContent: justifyContent ?? 'center' }}
      />
    </FormGroup>
  )
  return children
}

export default CheckboxComponent
