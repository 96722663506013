import Box from '@mui/system/Box'
import { PaperFlowComponent } from 'components/PaperFlowComponent'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import CheckboxComponent from 'components/checkbox'
import InputComponent from 'components/input'
import ToastComponent from 'components/toast'
import { formatIncompletePhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max'
import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { baseURL, defaultPhoneNumberCountry } from 'utils/constants'
import { MessageProps } from 'utils/types'
import { signInUser } from 'utils/users'
import { isValidPassword, isValidPhone } from 'utils/validations'
import './index.css'
import TextComponent from 'components/text'
import NavComponent from 'components/nav'

interface Props {
  onSignIn: (cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const navigate: NavigateFunction = useNavigate()
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>('')
  const [userPassword, setUserPassword] = useState<string>('')
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
  const onClose = () => navigate('/')

  const [msg, setMsg] = useState<MessageProps>()

  const formattedPhoneNumber: string = formatIncompletePhoneNumber(userPhoneNumber, defaultPhoneNumberCountry)
  const e164PhoneNumber: string = isValidPhone(userPhoneNumber, true)
    ? parsePhoneNumber(userPhoneNumber, defaultPhoneNumberCountry).format('E.164')
    : ''

  const isDisable: boolean = !isValidPhone(userPhoneNumber, true) || !isValidPassword(userPassword, true)
  return (
    <CenterComponent onClose={onClose}>
      <PaperFlowComponent title={'Welcome Back!'} subtitle={`Please sign in to access your account.`} />
      <InputComponent
        type='tel'
        label='Phone Number'
        isRequired={true}
        isError={!isValidPhone(userPhoneNumber)}
        onChange={(v: string) => setUserPhoneNumber(v)}
        value={isValidPhone(userPhoneNumber) ? formattedPhoneNumber : userPhoneNumber}
        width='80%'
      />
      <InputComponent
        type={isShowPassword ? 'type' : 'password'}
        label='Password'
        isRequired={true}
        onChange={(v: string) => setUserPassword(v)}
        value={userPassword ?? ''}
        width='80%'
      />
      <CheckboxComponent
        type='checkbox'
        color='warning'
        label='Show Password'
        isChecked={isShowPassword ? true : false}
        onChange={(v: any) => setIsShowPassword(v.target.checked)}
      />
      <ButtonComponent
        text='Sign In'
        disable={isDisable}
        mt={3}
        mb={2}
        onClick={async () => {
          const { msg, error } = await signInUser(e164PhoneNumber, userPassword)
          if (error) setMsg(msg)
          else {
            props.onSignIn(() => navigate('/'))
          }
        }}
      />
      <Box pt={3}>
        <TextComponent size='body2'>
          By continuing, you have read and agree to our{' '}
          <span style={{ color: '#beac48', fontWeight: 'bold', textDecoration: 'underline' }}>
            <NavComponent href={`${baseURL}/home/index.html#terms`} text='Terms and Conditions' isOpenNewTab={true} />
          </span>{' '}
          and{' '}
          <span style={{ color: '#beac48', fontWeight: 'bold', textDecoration: 'underline' }}>
            <NavComponent href={`${baseURL}/home/index.html#privacy`} text='Privacy Policy' isOpenNewTab={true} />
          </span>
          .
        </TextComponent>
      </Box>
      <Box display='flex' flexDirection='column' paddingTop={5}>
        <ButtonComponent size='small' variant='text' color='warning' text='Forgot Password?' href='/reset' />
        <ButtonComponent
          size='small'
          variant='text'
          color='warning'
          text='Are you a new user? Sign Up here'
          href='/signup'
        />
      </Box>
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </CenterComponent>
  )
}

export default Index
