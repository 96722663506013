import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { PaperFlowComponent } from 'components/PaperFlowComponent'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import SelectComponent from 'components/select'
import ToastComponent from 'components/toast'
import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { getSessionItem, removeSessionItem, setSessionItem } from 'utils/constants'
import { registerStudent } from 'utils/students'
import { MessageProps, UserProps } from 'utils/types'
import { isValidScheduleGroup } from 'utils/validations'
import './index.css'

interface Props {
  user?: Pick<UserProps, 'relation'>
  onAdd: (ssi: string, cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: School Name, Admins, Code, Schedule Groups, User Relation and onAdd Callbck
  const { user, onAdd } = props ?? {}
  const schoolName: string = getSessionItem('x-pool-sn') || ''
  const schoolCode: string = getSessionItem('x-pool-sc') || ''
  const schoolScheduleGroupNames: string[] = getSessionItem('x-pool-ssgn') || ''
  const userStudentRelationship: string = user?.relation ?? ''

  // states
  const [selectedScheduleGroup, setSelectedScheduleGroup] = useState<string>('')
  const [msg, setMsg] = useState<MessageProps>()

  // handlers
  const onClose = () => navigate('/')
  const onBack = () => navigate(-1)
  const onNext = async (): Promise<any> => {
    // Save schedule group for following steps
    setSessionItem('x-pool-sg', selectedScheduleGroup)
    navigate('/add/school')
  }
  const onSubmit = async (): Promise<any> => {
    // Register Student
    const { msg, data, error } = await registerStudent(
      schoolName,
      schoolCode,
      userStudentRelationship,
      selectedScheduleGroup,
      { address: '' },
      { address: '' },
    )
    if (error) setMsg(msg)
    else {
      // Clean up all saved items from add workflow
      removeSessionItem('x-pool-sn')
      removeSessionItem('x-pool-sa')
      removeSessionItem('x-pool-sc')
      removeSessionItem('x-pool-ssgn')
      // Return
      const id: string = (data?.studentId as string) ?? ''
      onAdd(id, () => {
        navigate(`/students?id=${id}`)
      })
    }
  }

  const isValid: boolean = isValidScheduleGroup(schoolScheduleGroupNames, selectedScheduleGroup, true)

  return (
    <CenterComponent onClose={onClose} onBack={onBack} spacing={2}>
      <PaperFlowComponent
        title={'Select School Schedule'}
        subtitle={`Your school has multiple start and dismissal times. Select your schedule.`}
      />
      <SelectComponent
        label='Select School Schedule'
        data_testid='schedule_testid'
        isRequired={true}
        isError={!isValidScheduleGroup(schoolScheduleGroupNames, selectedScheduleGroup)}
        options={schoolScheduleGroupNames} // Options from the fetched data
        value={selectedScheduleGroup}
        onChange={(v: string) => setSelectedScheduleGroup(v)}
      />
      {!userStudentRelationship ? (
        <ButtonComponent
          text='Next'
          disable={!isValid}
          endIcon={<ArrowForwardIcon />}
          onClick={() => onNext()}
          data_testId='next-btn'
        />
      ) : (
        <ButtonComponent text='Register' disable={!isValid} endIcon={<ArrowForwardIcon />} onClick={onSubmit} />
      )}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </CenterComponent>
  )
}

export default Index
