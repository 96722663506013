import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import { PaperFlowComponent } from 'components/PaperFlowComponent'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import InputComponent from 'components/input'
import NavComponent from 'components/nav'
import TextComponent from 'components/text'
import ToastComponent from 'components/toast'
import { formatIncompletePhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max'
import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import {
  baseURL,
  defaultPhoneNumberCountry,
  getSessionItem,
  passwordTooltip,
  phoneNumberRatesTooltip,
  phoneNumberTooltip,
  reenterPasswordTooltip,
  setSessionItem,
} from 'utils/constants'
import { MessageProps } from 'utils/types'
import { createUser } from 'utils/users'
import { isMatchingPassword, isValidName, isValidPassword, isValidPhone, isValidScheduleGroup } from 'utils/validations'
import './index.css'

function Index(): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: Schedule Groups, Selected Schedule
  const schoolScheduleGroupNames: string[] = getSessionItem('x-pool-ssgn') || ''
  const selectedScheduleGroup: string = getSessionItem('x-pool-sg') || ''

  // states
  const [userFirstName, setUserFirstName] = useState<string>('')
  const [userLastName, setUserLastName] = useState<string>('')
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>('')
  const [userPassword, setUserPassword] = useState<string>('')
  const [userReenteredPassword, setUserReenteredPassword] = useState<string>('')
  const [isConsent, setIsConsent] = useState<boolean>(false)
  const [msg, setMsg] = useState<MessageProps>()
  const formattedPhoneNumber: string = formatIncompletePhoneNumber(userPhoneNumber, defaultPhoneNumberCountry)
  const e164PhoneNumber: string = isValidPhone(userPhoneNumber, true)
    ? parsePhoneNumber(userPhoneNumber, defaultPhoneNumberCountry).format('E.164')
    : ''

  // handlers
  const onClose = () => navigate('/')
  const onBack = () => navigate(-1)
  const onNext = async (): Promise<any> => {
    const { msg, error } = await createUser(e164PhoneNumber, userPassword, `${userFirstName} ${userLastName}`.trim())
    if (error) setMsg(msg)
    else {
      // Save phone number and password for following steps
      setSessionItem('pn', e164PhoneNumber)
      setSessionItem('pwd', userPassword)
      navigate('/signup/verifyUser')
    }
  }

  const isValid: boolean =
    isValidScheduleGroup(schoolScheduleGroupNames, selectedScheduleGroup, true) && // Ensures user created after school verification steps
    isValidName(userFirstName, true) &&
    isValidName(userLastName, true) &&
    isValidPhone(userPhoneNumber, true) &&
    isValidPassword(userPassword, true) &&
    isMatchingPassword(userReenteredPassword, userPassword, true) &&
    isConsent

  return (
    <CenterComponent onClose={onClose} onBack={onBack} spacing={2}>
      <PaperFlowComponent title={'Create Your Account'} />
      <Box display='flex' flexDirection='column' justifyContent='center' paddingTop={2} gap={2}>
        <InputComponent
          type='text'
          label='First name of adult'
          isRequired={true}
          isError={!isValidName(userFirstName)}
          onChange={(v: string) => setUserFirstName(v)}
        />
        <InputComponent
          type='text'
          label='Last name of adult'
          isRequired={true}
          isError={!isValidName(userLastName)}
          onChange={(v: string) => setUserLastName(v)}
        />
        <InputComponent
          type='tel'
          label='Phone number of adult'
          isRequired={true}
          isError={!isValidPhone(userPhoneNumber)}
          helperText={
            !isValidPhone(userPhoneNumber)
              ? phoneNumberTooltip()
              : isValidPhone(userPhoneNumber, true)
                ? phoneNumberRatesTooltip()
                : ''
          }
          onChange={(v: string) => setUserPhoneNumber(v)}
          value={isValidPhone(userPhoneNumber) ? formattedPhoneNumber : userPhoneNumber}
        />
        <InputComponent
          type='password'
          label='Create Password'
          isRequired={true}
          isError={!isValidPassword(userPassword)}
          helperText={!isValidPassword(userPassword) ? passwordTooltip() : ''}
          onChange={(v: string) => setUserPassword(v)}
        />
        <InputComponent
          type='password'
          label='Reenter Password'
          isRequired={true}
          isError={!isMatchingPassword(userReenteredPassword, userPassword)}
          helperText={!isMatchingPassword(userReenteredPassword, userPassword) ? reenterPasswordTooltip() : ''}
          onChange={(v: string) => setUserReenteredPassword(v)}
        />
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <Checkbox
            size='small'
            color='warning' // Update checkbox state
            checked={isConsent ? true : false}
            onChange={(v: any) => setIsConsent(v.target.checked)}
          />
          <Box mt={1}>
            <TextComponent size='body2'>
              I agree to the Carpool School Inc.{' '}
              <span style={{ color: '#beac48', fontWeight: 'bold', textDecoration: 'underline' }}>
                <NavComponent
                  href={`${baseURL}/home/index.html#eula`}
                  text='End User License Agreement'
                  isOpenNewTab={true}
                />
              </span>
              .
            </TextComponent>
          </Box>
        </Box>
        <ButtonComponent
          text='Next'
          disable={!isValid}
          data_testId='next-btn'
          endIcon={<ArrowForwardIcon />}
          onClick={() => onNext()}
        />
      </Box>

      <Box pt={1} fontSize={14}>
        <>
          CARPOOL.SCHOOL DOES NOT VERIFY DRIVERS{"'"} LICENSES OR INSURANCE COVERAGE. USERS ARE RESPONSIBLE FOR
          SCREENING ALL PARTICIPANTS OF CARPOOL.
        </>
      </Box>
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </CenterComponent>
  )
}

export default Index
