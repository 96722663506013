import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { PaperFlowComponent } from 'components/PaperFlowComponent'
import VerificationCodeInput from 'components/VerificationCodeInput'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import TextComponent from 'components/text'
import ToastComponent from 'components/toast'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { getSessionItem, removeSessionItem } from 'utils/constants'
import { MessageProps } from 'utils/types'
import { resendCode, signInUser, verifyUser } from 'utils/users'
import { isValidCode } from 'utils/validations'
import './index.css'

interface Props {
  onSignUp: (cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: User name and password
  const e164PhoneNumber: string = getSessionItem('pn') || ''
  const userPassword: string = getSessionItem('pwd') || ''

  // states
  const [userCode, setUserCode] = useState<string>('')
  const [msg, setMsg] = useState<MessageProps>()
  const [isResendEnabled, setIsResendEnabled] = useState<boolean>(false)
  const [timer, setTimer] = useState<number>(30)

  // handlers
  const onClose = () => navigate('/')
  const onBack = () => navigate(-1)
  const onNext = async (): Promise<any> => {
    // Verify User
    const { msg, error } = await verifyUser(e164PhoneNumber, userCode)
    if (error) setMsg(msg)
    else {
      // SignIn User
      const { msg, error } = await signInUser(e164PhoneNumber, userPassword)
      if (error) setMsg(msg)
      else {
        // Clean up all saved items from create user workflow
        removeSessionItem('pn')
        removeSessionItem('pwd')
        // Return
        props.onSignUp(() => navigate('/add/school'))
      }
    }
  }
  // resend verification code
  const onResendCode = async () => {
    setIsResendEnabled(false)
    setTimer(30)
    const { msg, error } = await resendCode(e164PhoneNumber)
    if (error) setMsg(msg)
    else setMsg({ style: 'success', text: 'A new code has been sent to your phone.' })
  }

  // timer
  useEffect(() => {
    let interval: any
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else if (timer === 0) {
      setIsResendEnabled(true)
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [timer])

  return (
    <CenterComponent onClose={onClose} onBack={onBack} spacing={2}>
      <PaperFlowComponent
        title={'Let’s confirm your phone'}
        subtitle={`We have sent a code to ${e164PhoneNumber}. Please check your SMS messages.`}
      />
      <VerificationCodeInput onChange={(code: string) => setUserCode(code)} />
      <ButtonComponent
        text='Next'
        disable={!isValidCode(userCode)}
        data_testId='next-btn'
        endIcon={<ArrowForwardIcon />}
        onClick={() => onNext()}
      />
      <TextComponent size='body2'>Didn’t receive an SMS?</TextComponent>
      {isResendEnabled ? (
        <ButtonComponent variant='text' color='warning' text='Resend another code' onClick={onResendCode} />
      ) : (
        <TextComponent size='body2'>You can request another code in {timer}s</TextComponent>
      )}

      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </CenterComponent>
  )
}

export default Index
