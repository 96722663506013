import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Dialog, DialogTitle, Grid, IconButton, LinearProgress } from '@mui/material'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import ModalComponent from 'components/modal'
import PaperComponent from 'components/paper'
import PlacesAutocompleteComponent from 'components/places-autocomplete'
import SelectComponent from 'components/select'
import CircularProgressWithLabel from 'components/table/CircularProgressLabel'
import ToastComponent from 'components/toast'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { geocode } from 'utils/map'
import { updateStudentMorningSettings } from 'utils/students'
import { LocationProps, MessageProps, StudentProps } from 'utils/types'
import './index.css'
import InputComponent from 'components/input'
import { isValidComments } from 'utils/validations'

const MorningComponent = (props: any) => {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: School Name, Location, Student Pick up Location and onUpdate Callbck
  const { student, onUpdate } = props
  const { schoolName, schoolLocation, studentId, pickupLocation } = student
  const isNeedAMPool: boolean = pickupLocation && pickupLocation?.lat && pickupLocation?.lng ? true : false
  const { lat, lng } = schoolLocation
  const bounds = [lng - 0.7, lat - 0.7, lng + 0.7, lat + 0.7]

  // states
  const [studentPickupLocation, setStudentPickupLocation] = useState<LocationProps>({
    address: pickupLocation?.address || '',
  })
  const [pickupSeatsAvailable, setPickupSeatsAvailable] = useState<number>(student?.pickupSeatsAvailable)
  const [pickupComments, setPickupComments] = useState<string>(student?.pickupComments)
  const [delOpen, setDelOpen] = useState(false)
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')

  // handlers
  const onSubmit = async (): Promise<any> => {
    setIsLoading('Saving...')
    let pickupLocation: LocationProps = studentPickupLocation
    if (pickupLocation.address && (!pickupLocation?.lat || !pickupLocation?.lng)) {
      pickupLocation = await geocode(pickupLocation.address)
      if (!pickupLocation?.lat || !pickupLocation?.lng) {
        setMsg({
          style: 'error',
          text: 'We could not locate this address. Verify and re-enter address.',
        })
        setIsLoading('')
        return
      }
    }
    const { msg, error } = await updateStudentMorningSettings(
      schoolName,
      studentId,
      pickupLocation,
      pickupSeatsAvailable,
      pickupComments,
    )
    if (error) setMsg(msg)
    else onUpdate(() => navigate('/'))
    setStudentPickupLocation(pickupLocation)
    setIsLoading('')
  }
  // Function to close delete confirmation dialog
  const handleDelClose = () => {
    setDelOpen(false)
  }
  // Function to open dialog for delete confirmation
  const handleDelClick = () => {
    setDelOpen(true)
  }
  // Function to handle delete submit
  const handleDelSubmit = async () => {
    setDelOpen(false)
    setIsLoading('Deleting morning carpool request')
    const pickupLocation: LocationProps = { address: '' }
    const { msg, error } = await updateStudentMorningSettings(schoolName, studentId, pickupLocation)
    if (error) setMsg(msg)
    else onUpdate(() => navigate('/'))
    setStudentPickupLocation(pickupLocation)
    setIsLoading('')
  }

  const isValidAddress: boolean = studentPickupLocation.address ? true : false
  const isValid: boolean = isValidAddress && isValidComments(pickupComments)

  return (
    <CenterComponent marginTop={0}>
      {/***************** Morning Carpool *****************/}
      <PaperComponent title={'Morning Carpool'}>
        <PaperComponent subtitle={'Pickup Location'}>
          <Grid container>
            <Grid item xs>
              <PlacesAutocompleteComponent
                isRequired={true}
                label='Select Address / Landmark'
                defaultValue={studentPickupLocation.address}
                dataTestid='pickup-morning-testid'
                bounds={bounds}
                onChange={(v: string) => setStudentPickupLocation({ address: v })}
                onSubmit={(v: LocationProps) => setStudentPickupLocation(v)}
              />
            </Grid>
            {isNeedAMPool && (
              <>
                <IconButton aria-label='delete' color='error' onClick={handleDelClick} title='Remove Morning Carpool'>
                  <DeleteOutlinedIcon sx={{ fontSize: 22 }} data-testid='DeleteMorningCarpoolIcon' />
                </IconButton>
                <ModalComponent
                  isShow={delOpen}
                  title={`Remove Morning Carpool?`}
                  body={
                    'Removing the pickup address will also cancel your morning carpool enrollment. You will no longer see other families needing morning carpool.'
                  }
                  onActionCancel={handleDelClose}
                  onActionSubmit={handleDelSubmit}
                  submitActionType='Delete'
                />
              </>
            )}
          </Grid>
        </PaperComponent>
        <PaperComponent subtitle={'Available Seats'}>
          <SelectComponent
            label='Select seats'
            options={[1, 2, 3, 4, 5, 6, 7]}
            value={pickupSeatsAvailable ?? ''}
            onChange={(v: number) => setPickupSeatsAvailable(v)}
          />
        </PaperComponent>
        <PaperComponent subtitle={'Comments'}>
          <InputComponent
            type='text'
            rows={4}
            label='Comments'
            placeholder='Enter details that might help with carpooling arrangements'
            helperText={`Share any relevant info about your vehcile, vanpool interests, schedule, carpool preferences, or special needs.`}
            isRequired={false}
            isError={!isValidComments(pickupComments)}
            value={pickupComments}
            onChange={(v: string) => setPickupComments(v)}
          />
        </PaperComponent>
        <ButtonComponent
          text='Submit'
          width='full'
          disable={!isValid}
          endIcon={<ArrowForwardIcon />}
          onClick={onSubmit}
        />
      </PaperComponent>

      {/***************** Display progress *****************/}
      {isLoading && (
        <Dialog open={true}>
          <DialogTitle>{isLoading}</DialogTitle>
          <CircularProgressWithLabel />
        </Dialog>
      )}

      {/***************** Display messages *****************/}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </CenterComponent>
  )
}

interface Props {
  students: StudentProps[]
  processQuery: (id: string) => string
  onUpdate: (cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()

  // Inputs: Selected Student Id, and Callback
  const { students, processQuery, onUpdate } = props

  // We should never call this component without student id query search parameters, but this is just to be safe
  const studentIdFromQuery = searchParams.get('id') ?? ''
  useEffect(() => {
    setSearchParams({ id: processQuery(studentIdFromQuery) })
  }, [studentIdFromQuery, processQuery, setSearchParams])

  // Build props for child component
  const student: StudentProps | undefined = students?.find((s: StudentProps) => s.studentId === studentIdFromQuery)

  if (!student) return <LinearProgress />
  return <MorningComponent student={student} onUpdate={onUpdate} />
}

export default Index
