import axios, { AxiosBasicCredentials } from 'axios'
import { baseURL, getItem, isProd, setItem } from 'utils/constants'
import { MessageProps } from 'utils/types'

const axiosInstance1 = axios.create()
const axiosInstance2 = axios.create()
const timeout = 5000
const validateStatus = (status: number): boolean => status < 300

export const http = async (
  method: string,
  url: string,
  schoolName?: string,
  body?: Record<string, unknown>,
): Promise<any> => {
  const res = await axiosInstance1({
    method,
    baseURL,
    url: encodeURI(url),
    timeout,
    validateStatus,
    ...(schoolName ? { headers: { 'x-pool-school': schoolName } } : {}),
    ...(body ? { data: body } : {}),
  })
  if (!isProd()) console.log(res)
  return res
}

export const httpBasicAuth = async (
  method: string,
  url: string,
  auth: AxiosBasicCredentials,
  schoolName?: string,
  body?: Record<string, unknown>,
): Promise<any> => {
  const res = await axiosInstance1({
    method,
    baseURL,
    url: encodeURI(url),
    timeout,
    validateStatus,
    auth,
    ...(schoolName ? { headers: { 'x-pool-school': schoolName } } : {}),
    ...(body ? { data: body } : {}),
  })
  if (!isProd()) console.log(res)
  setItem('tokens', res.data.tokens)
  return res
}

axiosInstance2.interceptors.request.use(
  async (config: any) => {
    if (!config.headers) config.headers = {}
    config.headers['Authorization'] = `Bearer ${getItem('tokens')?.IdToken}`
    return config
  },
  (error) => Promise.reject(error),
)
axiosInstance2.interceptors.response.use(
  (res) => res,
  async (error) => {
    const { config } = error
    if (error?.response?.status === 401 && !config._retry) {
      config._retry = true
      const res = await http('post', `/api/refreshUserToken`)
      setItem('tokens', res.data.tokens)
      config.headers['Authorization'] = `Bearer ${getItem('tokens')?.IdToken}`
      return axios(config)
    }
    return Promise.reject(error)
  },
)
export const httpBearerAuth = async (
  method: string,
  url: string,
  schoolName?: string,
  body?: Record<string, unknown>,
): Promise<any> => {
  const res = await axiosInstance2({
    method,
    baseURL,
    url: encodeURI(url),
    timeout,
    validateStatus,
    ...(schoolName ? { headers: { 'x-pool-school': schoolName } } : {}),
    ...(body ? { data: body } : {}),
  })
  if (!isProd()) console.log(res)
  return res
}

export const httpCodeAuth = async (
  method: string,
  url: string,
  body?: Record<string, unknown>,
  to?: number,
): Promise<any> => {
  const res = await axiosInstance1({
    method,
    baseURL,
    url: encodeURI(url),
    timeout: to || timeout,
    validateStatus,
    headers: {
      'x-pool-school': getItem('x-pool-sn'),
      'x-pool-school-admin-email': getItem('x-pool-sae'),
      'x-pool-school-admin-code': getItem('x-pool-sac'),
    },
    ...(body ? { data: body } : {}),
  })
  if (!isProd()) console.log(res)
  return res
}

export const processError = (error: any): MessageProps => {
  if (!isProd()) console.log(error)

  if (error?.response?.data?.reason) {
    const msg: MessageProps = {
      style: 'error',
      text: error.response.data.reason,
    }
    return msg
  } else {
    const msg: MessageProps = {
      style: 'error',
      heading: 'Oops, Something Went Wrong',
      text: `We encountered an issue while processing your request. Please try your operation again. If the issue persists, please don't hesitate to contact our support team at support@carpool.school. We'll be happy to assist you further. Thank you for your understanding.`,
    }
    if (!isProd() && error.isAxiosError) msg.text += error.message
    return msg
  }
}
