const BackComponent = (props: any): JSX.Element => {
  const { text, onClick } = props

  return (
    <div className='flex p-4 pl-5 pr-5  gap-3 items-center'>
      <div className='flex items-center gap-2 cursor-pointer' onClick={onClick} data-testid='back-button-testid'>
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
          <path d='M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z' fill='#68727D' />
        </svg>
      </div>
      <div className='text-[color:var(--Color-Tokens-Text-Modes-Base-Second,#68727D)] font-figtree font-semibold leading-[24px]'>
        {text}
      </div>
    </div>
  )
}

export default BackComponent
