// Sample events calendar build, explained and detailed over at
// https://justacoding.blog/react-calendar-component-example-with-events/

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ButtonComponent from 'components/button'
import ModalComponent from 'components/modal'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { getTimeNumber, getTimeString, getTimeStringFromNumber } from 'utils/constants'
import { CalendarEventProps, CalendarProps } from 'utils/types'
import './index.css'

// Interfaces
type CalendarNavigationProps = {
  date: Date
  setDate: (v: Date) => void
}

type CalendarEventFormProps = {
  event: CalendarShowEventProps
  onChange: (v?: CalendarProps) => void
}

type CalendarShowEventProps = {
  from: Date
  eventState?: 'HOLIDAY' | 'DELETED'
}

type CalendarGridProps = {
  date: Date
  actualDate: Date
  events: CalendarEventProps[]
  defaultEvents: CalendarEventProps[]
  setShowEvent: (v: CalendarShowEventProps | null) => void
}

// Calendar Components
// Top bar, contains the month/year combo as well as back/forward links
const Navigation = ({ date, setDate }: CalendarNavigationProps) => {
  const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

  return (
    <div className='navigation'>
      <div
        className='back'
        onClick={() => {
          const newDate = new Date(date)
          newDate.setMonth(newDate.getMonth() - 1)
          setDate(newDate)
        }}
      >
        {'<-'} {MONTHS[date.getMonth() === 0 ? 11 : date.getMonth() - 1]}
      </div>

      <div className='monthAndYear'>
        {MONTHS[date.getMonth()]} {date.getFullYear()}
      </div>

      <div
        className='forward'
        onClick={() => {
          const newDate = new Date(date)
          newDate.setMonth(newDate.getMonth() + 1)
          setDate(newDate)
        }}
      >
        {MONTHS[date.getMonth() === 11 ? 0 : date.getMonth() + 1]} {'->'}
      </div>
    </div>
  )
}

const DayLabels = (day: string, idx: number) => (
  <div key={idx} className='dayLabel cell'>
    {day}
  </div>
)

// Form to add new events or edit existing events
// In a real implementation, we'd have some frontend
// validation and also the equivalent in our
// backend service...
const EventForm = ({ event, onChange }: CalendarEventFormProps) => {
  const [selectedEvent, setSelectedEvent] = useState<CalendarEventProps>({ from: event.from })
  const body: JSX.Element = (
    <DatePicker
      popperProps={{ positionFixed: true }}
      open={true}
      selected={selectedEvent.from}
      showTimeSelect
      timeFormat='hh:mm a'
      timeIntervals={15}
      timeCaption='time'
      dateFormat='MMMM d, yyyy h:mm aa'
      disabledKeyboardNavigation
      placeholderText='Select from calendar'
      onChange={(d: Date) => setSelectedEvent({ ...selectedEvent, from: d })}
      readOnly
      fixedHeight
    />
  )
  const actions: JSX.Element[] = [
    <ButtonComponent
      key={0}
      size='small'
      type='submit'
      text='Update Time'
      disable={!(selectedEvent.from instanceof Date)}
      onClick={() => onChange({ ...selectedEvent, userAction: 'UPSERT' })}
    />,
  ]
  if (event.eventState !== 'DELETED') {
    actions.unshift(
      <ButtonComponent
        key={1}
        size='small'
        text={`Don't need carpool`}
        onClick={() => onChange({ ...selectedEvent, userAction: 'DELETE' })}
      />,
    )
  }
  return (
    <ModalComponent
      isShow={true}
      title='Set pickup times from school'
      body={body}
      actions={actions}
      onClose={() => onChange()}
      onActionCancel={() => onChange()}
      width='400px'
      height='350px'
    />
  )
}

// The grid of days, renders a month's worth of days and
// also populates the events on the relevant dates
const Grid = ({ date, actualDate, events, defaultEvents, setShowEvent }: CalendarGridProps) => {
  const theme = useTheme()
  const isUpSM: boolean = useMediaQuery(theme.breakpoints.up('sm'))
  const padding: string | undefined = isUpSM ? '0px 0px' : '0px 0px'
  const minWidth: string | undefined = isUpSM ? undefined : 'unset'
  const width: number | undefined = isUpSM ? undefined : 35
  const toStartOfDay = (date: Date): Date => {
    const newDate = new Date(date)
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    return newDate
  }

  const findEventsForDate = (events: CalendarEventProps[], date: Date): CalendarEventProps[] => {
    return events.filter((event: CalendarEventProps) => date.getTime() === toStartOfDay(event.from).getTime())
  }

  // Finds the closest Monday relative to the first day of
  // the target month/year combination
  // Then increment upon this day until we have a full set
  // of date objects to work with
  const startingDate = new Date(date.getFullYear(), date.getMonth(), 1)
  startingDate.setDate(startingDate.getDate() - (startingDate.getDay() - 1))
  const monthsEvents: { date: Date; event: CalendarEventProps; defaultEvent: CalendarEventProps }[] = []
  const WEEKS_COUNT: number = 6,
    DAYS_COUNT: number = 7
  for (let i = 0; i < WEEKS_COUNT * DAYS_COUNT; i++) {
    const date = new Date(startingDate)
    const isWeekday = (): boolean => date.getDay() !== 0 && date.getDay() !== 6
    // skip saturday and sunday weekend days - set cell Width in CSS tp 20% and use Mon-Fri 5 day labels below navigation
    if (isWeekday()) {
      monthsEvents.push({
        date,
        event: findEventsForDate(events, date)[0],
        defaultEvent: findEventsForDate(defaultEvents, date)[0],
      })
    }
    startingDate.setDate(startingDate.getDate() + 1)
  }
  const currentDate: Date = toStartOfDay(new Date())

  return (
    <>
      {monthsEvents.map((v, i) => {
        const isPast = v.date.getTime() < currentDate.getTime()
        const { from, eventState } = v.event ?? {}
        return (
          <div key={i} className={`cell ${isPast ? 'past' : ''}`}>
            {v.date.getDate()}
            <div>
              {!v.event && !isPast && (
                <ButtonComponent
                  size='small'
                  padding={'0px 0px'}
                  minWidth={minWidth}
                  width={width}
                  variant='outlined'
                  color='warning'
                  text={''}
                  onClick={() => {
                    setShowEvent({
                      from: v.defaultEvent.from,
                    })
                  }}
                />
              )}
              {v.event && (
                <ButtonComponent
                  size='small'
                  padding={padding}
                  minWidth={minWidth}
                  width={width}
                  variant='text'
                  color={eventState === 'DELETED' ? 'error' : 'warning'}
                  text={
                    eventState === 'DELETED'
                      ? 'X'
                      : eventState === 'HOLIDAY'
                        ? 'H'
                        : isUpSM
                          ? getTimeString(getTimeNumber(from))
                          : getTimeStringFromNumber(getTimeNumber(from))
                  }
                  disable={isPast || eventState === 'HOLIDAY'}
                  onClick={() => {
                    setShowEvent({
                      from: eventState === 'DELETED' ? v.defaultEvent.from : from,
                      eventState,
                    })
                  }}
                />
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}

export const CalendarComponent = (props: any) => {
  const events: CalendarEventProps[] = props.events
  const defaultEvents: CalendarEventProps[] = props.defaultEvents

  const [date, setDate] = useState<Date>(props?.date ?? new Date())
  const [showEvent, setShowEvent] = useState<CalendarShowEventProps | null>()

  const onEvent = async (event?: CalendarEventProps) => {
    if (event) {
      setShowEvent(null)
      await props.onChange(event)
    } else setShowEvent(null)
  }

  return (
    <div className='calendar'>
      <Navigation date={date} setDate={setDate} />

      {['Mon', 'Tue', 'Wed', 'Thu', 'Fri'].map(DayLabels)}

      <Grid date={date} actualDate={date} events={events} defaultEvents={defaultEvents} setShowEvent={setShowEvent} />

      {showEvent && <EventForm event={showEvent} onChange={onEvent} />}
    </div>
  )
}
