import { http, httpBasicAuth, httpBearerAuth, processError } from 'utils/requests'
import { MessageProps, ResponseProps } from 'utils/types'

const lookupUserStatusMsg: {
  [status: string]: MessageProps
} = {
  created: {
    style: 'light',
    heading: 'User has been created.',
    text: 'Enter the 6 digit verification code sent to the phone number.',
  },
  verified: {
    style: 'light',
    heading: 'Student has been registered. You are all set!',
    text: 'Use the phone number and password to sign in and managed your registered student.',
  },
  forgot_password: {
    style: 'light',
    heading: 'Enter the verification code sent to the phone number to reset your password.',
    text: '',
  },
  set_password: {
    style: 'light',
    heading: 'Password was set successfully.',
    text: '',
  },
  reset_password: {
    style: 'light',
    heading: 'Password was reset successfully.',
    text: '',
  },
}

export const createUser = async (phoneNumber: string, password: string, name: string): Promise<ResponseProps> => {
  try {
    await http('post', `/api/users`, '', { phoneNumber, password, name })
    return { msg: lookupUserStatusMsg['created'] }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}
export const resendCode = async (phoneNumber: string): Promise<ResponseProps> => {
  try {
    await http('post', `/api/resendCode`, '', { phoneNumber })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}
export const verifyUser = async (phoneNumber: string, code: string): Promise<ResponseProps> => {
  try {
    await http('post', `/api/verifyUser`, '', { phoneNumber, code })
    return { msg: lookupUserStatusMsg['verified'] }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const signInUser = async (phoneNumber: string, password: string): Promise<ResponseProps> => {
  try {
    await httpBasicAuth('post', `/api/signInUser`, { username: phoneNumber, password })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const signOutUser = async (): Promise<ResponseProps> => {
  try {
    await httpBearerAuth('post', `/api/signOutUser`)
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const updateUserPassword = async (
  phoneNumber: string,
  accessToken: string,
  password: string,
  newPassword: string,
): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('post', `/api/updateUserPassword`, '', {
      phoneNumber,
      accessToken,
      password,
      newPassword,
    })
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const forgotUserPassword = async (phoneNumber: string): Promise<ResponseProps> => {
  try {
    await http('post', `/api/forgotUserPassword`, '', { phoneNumber })
    return { msg: lookupUserStatusMsg['forgot_password'] }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const setUserPassword = async (
  phoneNumber: string,
  tempPassword: string,
  password: string,
): Promise<ResponseProps> => {
  try {
    await http('post', `/api/setUserPassword`, '', { phoneNumber, tempPassword, password })
    return { msg: lookupUserStatusMsg['set_password'] }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const resetUserPassword = async (
  phoneNumber: string,
  code: string,
  password: string,
): Promise<ResponseProps> => {
  try {
    await http('post', `/api/resetUserPassword`, '', { phoneNumber, code, password })
    return { msg: lookupUserStatusMsg['reset_password'] }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const updateUser = async (
  phoneNumber: string,
  name?: string,
  gender?: string,
  userSubscription?: Record<string, any>,
): Promise<ResponseProps> => {
  try {
    const body = {
      // check for truthy since falsy values are not allowed
      ...(name ? { name } : {}),
      ...(gender ? { gender } : {}),
      userSubscription,
    }
    const { data } = await httpBearerAuth('put', `/api/users/${phoneNumber}`, '', body)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const deleteUser = async (phoneNumber: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('delete', `/api/users/${phoneNumber}`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getUser = async (phoneNumber: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/users/${phoneNumber}`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}
