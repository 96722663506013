import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import ModalComponent from 'components/modal'
import { useState } from 'react'
import './index.css'

const ModalButtonComponent = (props: any): JSX.Element => {
  const { size, variant, color, disable, text, tooltip, title, body, startIcon, endIcon, onClick } = props ?? {}
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const btn: JSX.Element = (
    <Button
      size={size || 'large'}
      variant={variant || 'contained'}
      color={color || 'secondary'}
      disabled={disable}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={handleShow}
    >
      {text}
    </Button>
  )

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip} followCursor>
          <span>{btn}</span>
        </Tooltip>
      ) : (
        btn
      )}
      <ModalComponent
        isShow={show}
        title={title}
        body={body}
        onActionCancel={handleClose}
        onActionSubmit={async () => {
          await onClick()
          handleClose()
        }}
      />
    </>
  )
}

export default ModalButtonComponent
