import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import InputComponent from 'components/input'
import MsgComponent from 'components/msg'
import { formatIncompletePhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max'
import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { defaultPhoneNumberCountry, passwordTooltip, phoneCodeTooltip, reenterPasswordTooltip } from 'utils/constants'
import { forgotUserPassword, resetUserPassword } from 'utils/users'
import { isMatchingPassword, isValidCode, isValidPassword, isValidPhone } from 'utils/validations'
import './index.css'
import { MessageProps } from 'utils/types'
import ToastComponent from 'components/toast'

function Index(): JSX.Element {
  const navigate: NavigateFunction = useNavigate()
  // user states
  const [vars, setVars] = useState<any>({
    step: 1,
    headerStatusMsg: {},
    userPhoneNumber: '',
    userCode: '',
    userPassword: '',
    userReenteredPassword: '',
  })
  const { step, headerStatusMsg, userPhoneNumber, userCode, userPassword, userReenteredPassword } = vars
  const [msg, setMsg] = useState<MessageProps>()

  const onClose = () => navigate(-1)
  const formattedPhoneNumber: string = formatIncompletePhoneNumber(userPhoneNumber, defaultPhoneNumberCountry)
  const e164PhoneNumber: string = isValidPhone(userPhoneNumber, true)
    ? parsePhoneNumber(userPhoneNumber, defaultPhoneNumberCountry).format('E.164')
    : ''

  switch (step) {
    case 1:
      return (
        <CenterComponent onClose={onClose}>
          <h3>Enter your phone number</h3>
          <p>You will receive a verification code on this number to reset your password</p>
          <InputComponent
            key='1a'
            type='text'
            label='Phone Number'
            isRequired={true}
            isError={!isValidPhone(userPhoneNumber)}
            onChange={(v: string) => setVars({ ...vars, userPhoneNumber: v })}
            value={isValidPhone(userPhoneNumber) ? formattedPhoneNumber : userPhoneNumber}
          />
          <ButtonComponent
            key='1b'
            text='SEND CODE'
            disable={!isValidPhone(userPhoneNumber, true)}
            onClick={async () => {
              const { msg, error } = await forgotUserPassword(e164PhoneNumber)
              if (error) setMsg(msg)
              else setVars({ ...vars, headerStatusMsg: msg, step: 2 })
            }}
          />
          {msg ? (
            <ToastComponent
              style={msg?.style}
              heading={msg?.heading}
              text={msg?.text}
              onClose={() => setMsg(undefined)}
            />
          ) : (
            <></>
          )}
        </CenterComponent>
      )
    case 2:
      const isValid: boolean =
        isValidCode(userCode) &&
        isValidPassword(userPassword, true) &&
        isMatchingPassword(userReenteredPassword, userPassword, true)
      return (
        <CenterComponent onClose={onClose}>
          <MsgComponent
            key='2a'
            style={headerStatusMsg.style}
            heading={headerStatusMsg.heading}
            text={headerStatusMsg.text}
          />
          <InputComponent
            key='2b'
            type='text'
            label='Verification code'
            isRequired={true}
            helperText={phoneCodeTooltip}
            onChange={(v: string) => {
              setVars({ ...vars, userCode: v })
            }}
          />
          <InputComponent
            key='2c'
            type='password'
            label='Password'
            isRequired={true}
            isError={!isValidPassword(userPassword)}
            helperText={!isValidPassword(userPassword) ? passwordTooltip() : ''}
            onChange={(v: string) => {
              setVars({ ...vars, userPassword: v })
            }}
          />
          <InputComponent
            key='2d'
            type='password'
            label='Reenter Password'
            isRequired={true}
            isError={!isMatchingPassword(userReenteredPassword, userPassword)}
            helperText={!isMatchingPassword(userReenteredPassword, userPassword) ? reenterPasswordTooltip() : ''}
            onChange={(v: string) => {
              setVars({ ...vars, userReenteredPassword: v })
            }}
          />
          <ButtonComponent
            key='2e'
            text='RESET PASSWORD'
            disable={!isValid}
            onClick={async () => {
              const { msg, error } = await resetUserPassword(e164PhoneNumber, userCode, userPassword)
              if (error) setMsg(msg)
              else setVars({ ...vars, headerStatusMsg: msg, step: 3 })
            }}
          />
          {msg ? (
            <ToastComponent
              style={msg?.style}
              heading={msg?.heading}
              text={msg?.text}
              onClose={() => setMsg(undefined)}
            />
          ) : (
            <></>
          )}
        </CenterComponent>
      )
    case 3:
      return (
        <CenterComponent onClose={onClose}>
          <MsgComponent
            key='3a'
            style={headerStatusMsg.style}
            heading={headerStatusMsg.heading}
            text={headerStatusMsg.text}
          />
        </CenterComponent>
      )
    default:
      return <></>
  }
}

export default Index
