import Paper from '@mui/material/Paper'
import TextComponent from 'components/text'
import Box from '@mui/material/Box'

export const PaperFlowComponent = (props: any): JSX.Element => {
  const { title, subtitle, children } = props ?? {}
  return (
    <Paper variant='outlined' sx={{ p: 1, background: 'transparent', border: 'none' }}>
      {typeof title === 'string' ? (
        <TextComponent align='center' size='h5' fontWeight='bold'>
          {title}
        </TextComponent>
      ) : (
        title
      )}
      {subtitle && (
        <TextComponent align='center' fontStyle='bold' size='body2' pt={2}>
          {subtitle}
        </TextComponent>
      )}
      <Box sx={{ paddingTop: 1 }}>{children}</Box>
    </Paper>
  )
}
