import { Dialog, DialogContent } from '@mui/material'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import AvatarComponent from 'components/avatar'
import BackComponent from 'components/back'
import { useState } from 'react'
import ActivitiesIcon from '../../images/activities_icon.png'
import CommentIcon from '../../images/comments_icon.png'
import CopyIcon from '../../images/copy_icon.png'
import LineIcon from '../../images/vertical_bar_icon.png'
import TimeIcon from '../../images/time_icon.png'
import './index.css'

const DialogButtonComponent = (props: any): JSX.Element => {
  const {
    size,
    variant,
    color,
    disable,
    text,
    tooltip,
    title,
    onClick,
    userRelationship,
    contact,
    distance,
    scheduleName,
    seatsAvailable,
    comments,
    time,
    activities,
  } = props ?? {}
  const [show, setShow] = useState(false)
  const [tooltipText, setTooltipText] = useState('Copy to clipboard')

  const handleCopy = () => {
    navigator.clipboard.writeText(contact).then(
      () => {
        setTooltipText('Copied!')
        setTimeout(() => {
          setTooltipText('Copy to clipboard')
        }, 3000) // Reset the tooltip after 2 seconds
      },
      () => {
        setTooltipText('Failed to copy')
        setTimeout(() => {
          setTooltipText('Copy to clipboard')
        }, 3000) // Reset the tooltip after 2 seconds
      },
    )
  }

  // Use the theme and media query to determine if the dialog should be full-screen
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const btn: JSX.Element = (
    <Button
      size={size || 'large'}
      variant={variant || 'contained'}
      color={color || 'secondary'}
      disabled={disable}
      onClick={handleShow}
    >
      {text}
    </Button>
  )

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip} followCursor>
          <span>{btn}</span>
        </Tooltip>
      ) : (
        btn
      )}
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby='dialog-title'
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            paddingTop: 'env(safe-area-inset-top)',
            paddingBottom: 'env(safe-area-inset-bottom)',
          },
        }}
      >
        <BackComponent text={title} onClick={handleClose}></BackComponent>
        <DialogContent>
          <div className='flex items-center gap-5 flex-1'>
            <div className='flex items-center gap-3 flex-1'>
              <div className='flex w-10 h-10 p-1.5 justify-center items-center rounded-full bg-[#F7F7F8]'>
                <AvatarComponent name={title}></AvatarComponent>
              </div>
              <div className='flex flex-col'>
                <div className='flex items-center gap-1'>
                  <span className='text-[#474747] font-figtree text-sm font-medium'>{title}</span>
                  <span className='w-[1px] bg-gray-300 h-[20px]' />
                  <span className='text-[#797979] font-figtree text-xs font-medium'>{userRelationship}</span>
                </div>
                <span className='text-[#FF7900] font-figtree text-sm font-normal'>{distance} away</span>
              </div>
            </div>
            {seatsAvailable && (
              <div className='flex items-center '>
                <div className='text-xs inline-block bg-custom-yellow font-normal text-headings px-2 py-1 rounded-2xl'>
                  {seatsAvailable} seats available
                </div>
              </div>
            )}
          </div>
          <div className='font-figtree pt-3'>
            <div className='flex items-center gap-2'>
              {time && (
                <div className='flex items-center gap-1'>
                  {time && (
                    <>
                      <img src={TimeIcon} alt='' />
                      <span className='text-headings text-sm font-normal'>{time}</span>
                    </>
                  )}
                </div>
              )}
              {time && scheduleName && <img src={LineIcon} alt='' />}
              {scheduleName && (
                <>
                  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
                    <path
                      d='M15 2.25H14.25V0.75H12.75V2.25H5.25V0.75H3.75V2.25H3C2.175 2.25 1.5 2.925 1.5 3.75V15.75C1.5 16.575 2.175 17.25 3 17.25H15C15.825 17.25 16.5 16.575 16.5 15.75V3.75C16.5 2.925 15.825 2.25 15 2.25ZM15 15.75H3V7.5H15V15.75ZM15 6H3V3.75H15V6Z'
                      fill='#E5C265'
                    />
                  </svg>{' '}
                  <span className='text-headings text-sm font-normal'>{scheduleName}</span>
                </>
              )}
            </div>
          </div>
          {comments && (
            <div className='font-figtree pt-8'>
              <div className='flex items-center gap-2'>
                <img src={CommentIcon} alt='' /> Comments
              </div>
              <div className='text-sm pt-2 text-disable'>{comments}</div>
            </div>
          )}
          {activities && (
            <div className='font-figtree pt-8'>
              <div className='flex items-center gap-2'>
                <img src={ActivitiesIcon} alt='' /> Activities
              </div>
              <div className='text-sm pt-2 text-disable' data-testid='row-testid'>
                {activities}
              </div>
            </div>
          )}
        </DialogContent>
        <div className='px-5'>
          <div className='border-b border-gray-200'></div>
          <div className='font-figtree pt-5'>
            <div className='flex items-center gap-2' style={{ textAlign: 'left' }}>
              <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                <path
                  d='M6.54 5C6.6 5.89 6.75 6.76 6.99 7.59L5.79 8.79C5.38 7.59 5.12 6.32 5.03 5H6.54ZM16.4 17.02C17.25 17.26 18.12 17.41 19 17.47V18.96C17.68 18.87 16.41 18.61 15.2 18.21L16.4 17.02ZM7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.51C21 15.96 20.55 15.51 20 15.51C18.76 15.51 17.55 15.31 16.43 14.94C16.33 14.9 16.22 14.89 16.12 14.89C15.86 14.89 15.61 14.99 15.41 15.18L13.21 17.38C10.38 15.93 8.06 13.62 6.62 10.79L8.82 8.59C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3Z'
                  fill='#474747'
                />
              </svg>{' '}
              <span className='font-medium text-md text-headings'>Contact Info</span>
            </div>
            <div className='flex gap-3'>
              <div className='flex flex-grow mt-2 items-center gap-2 justify-center text-gray-400 bg-light-yellow border border-custom-yellow py-2 rounded-lg'>
                <Tooltip title={tooltipText} arrow>
                  <img src={CopyIcon} alt='' onClick={handleCopy} />
                </Tooltip>
                {contact}
              </div>
              {onClick && (
                <div
                  className='flex mt-2 items-center gap-2 justify-center text-gray-400 bg-light-yellow border border-custom-yellow py-2 px-3 rounded-lg cursor-pointer'
                  onClick={onClick}
                >
                  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                    <path
                      d='M11.6665 11.666V14.166H4.99984V8.33268C4.99984 6.49102 6.4915 4.99935 8.33317 4.99935C9.0415 4.99935 9.69984 5.21602 10.2332 5.59935L11.4248 4.40768C10.8915 3.98268 10.2665 3.67435 9.58317 3.49935V2.91602C9.58317 2.22435 9.02484 1.66602 8.33317 1.66602C7.6415 1.66602 7.08317 2.22435 7.08317 2.91602V3.49935C4.92484 4.04935 3.33317 6.00768 3.33317 8.33268V14.166H1.6665V15.8327H14.9998V14.166H13.3332V11.666H11.6665ZM8.33317 18.3327C9.24984 18.3327 9.99984 17.5827 9.99984 16.666H6.6665C6.6665 17.5827 7.4165 18.3327 8.33317 18.3327ZM18.3332 6.66602H15.8332V4.16602H14.1665V6.66602H11.6665V8.33268H14.1665V10.8327H15.8332V8.33268H18.3332V6.66602Z'
                      fill='#666666'
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
          <br />
          <br />
        </div>
      </Dialog>
    </>
  )
}

export default DialogButtonComponent
