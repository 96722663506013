import FilterListIcon from '@mui/icons-material/FilterList'
import { Button, Checkbox, FormControlLabel, FormGroup, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'

const FilterComponent = ({
  label,
  options,
  onChange,
  testid,
}: {
  label: string
  options: Set<string>
  onChange: (selectedOptions: Set<string>) => void
  testid?: string
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedOptions, setSelectedOptions] = useState<Set<string>>(new Set())

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target
    const a: Set<string> = new Set(selectedOptions)
    checked ? a.add(name) : a.delete(name)
    setSelectedOptions(a)
    onChange(a)
  }

  return (
    <div data-testid={`${testid}-div`}>
      <Button variant='text' size='small' onClick={handleClick} startIcon={<FilterListIcon sx={{ fontSize: 20 }} />}>
        {label}
      </Button>
      <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <FormGroup>
          {Array.from(options).map((v: string) => (
            <MenuItem key={v} dense>
              <FormControlLabel
                control={<Checkbox checked={selectedOptions.has(v)} onChange={handleChange} name={v} />}
                label={v}
              />
            </MenuItem>
          ))}
        </FormGroup>
      </Menu>
    </div>
  )
}

export default FilterComponent
