import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import DoneIcon from '@mui/icons-material/Done'
import SendIcon from '@mui/icons-material/Send'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { ReactNode } from 'react'
import './index.css'

const ModalComponent = (props: any): JSX.Element => {
  const { isShow, onClose, title, body, actions, onActionSubmit, onActionCancel, submitActionType, width, height } =
    props ?? {}
  const dialogPaperProps = width ? { width, minWidth: width } : {}
  const dialogContentSystemProps = height ? { height } : {}
  const lookupSubmitActionColor: Record<string, any> = {
    Delete: 'error',
    Disable: 'error',
    Unsubscribe: 'error',
    'Send Link': 'secondary',
  }
  const lookupSubmitAction: Record<string, ReactNode> = {
    Delete: <DeleteOutlinedIcon sx={{ fontSize: 20 }} />,
    Disable: <DeleteOutlinedIcon sx={{ fontSize: 20 }} />,
    Unsubscribe: <DeleteOutlinedIcon sx={{ fontSize: 20 }} />,
    'Send Link': <SendIcon sx={{ fontSize: 20 }} />,
  }
  return (
    <Dialog
      open={isShow}
      onClose={onActionCancel}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      scroll='paper'
      data-testid='modal-dialog'
      PaperProps={{ sx: { ...dialogPaperProps } }}
    >
      {onClose && (
        <IconButton onClick={onClose} style={{ position: 'absolute', top: '10px', right: '8px' }}>
          <CloseIcon />
        </IconButton>
      )}
      {title && <DialogTitle>{title}</DialogTitle>}
      {body && <DialogContent sx={{ ...dialogContentSystemProps }}>{body}</DialogContent>}
      {actions ? (
        <DialogActions>
          <>{actions.map((v: any) => v)}</>
        </DialogActions>
      ) : (
        <DialogActions>
          {onActionSubmit && (
            <Button
              onClick={onActionSubmit}
              color={lookupSubmitActionColor[submitActionType] ?? 'secondary'}
              variant='contained'
              sx={{ display: 'flex', alignItems: 'center' }}
              startIcon={lookupSubmitAction[submitActionType] ?? <DoneIcon sx={{ fontSize: 20 }} />}
              data-testid='modal-dialog-action-submit'
            >
              {submitActionType ?? 'Submit'}
            </Button>
          )}
          {onActionCancel && (
            <Button
              onClick={onActionCancel}
              variant='contained'
              color='secondary'
              startIcon={<CloseIcon sx={{ fontSize: 20 }} />}
              autoFocus
            >
              Cancel
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default ModalComponent
