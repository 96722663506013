import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import './index.css'

const ValueChartComponent = (props: any) => {
  const { title, value, avatar, data_testid } = props

  return (
    <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{ p: 1 }}>
      <Card raised>
        <CardContent>
          <Stack alignItems='flex-start' direction='row' justifyContent='space-between'>
            <Stack spacing={1}>
              <Typography color='text.secondary'>{title}</Typography>
              <Typography variant='h4' data-testid={data_testid}>
                {value}
              </Typography>
            </Stack>
            {avatar}
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default ValueChartComponent
