import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import InputComponent from 'components/input'
import NavComponent from 'components/nav'
import { PaperFlowComponent } from 'components/PaperFlowComponent'
import TextComponent from 'components/text'
import ToastComponent from 'components/toast'
import TypeaheadComponent from 'components/typeahead'
import VerificationCodeInput from 'components/VerificationCodeInput'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { baseURL, clearItems, getItem, setItems } from 'utils/constants'
import { getSchool, verifySchoolAdminCode, verifySchoolAdminEmail } from 'utils/schools'
import { MessageProps } from 'utils/types'
import { isValidEmail } from 'utils/validations'
import './index.css'

const Index = (props: any): JSX.Element => {
  const navigate: NavigateFunction = useNavigate()
  const [searchParams] = useSearchParams()

  // school states
  const [vars, setVars] = useState<any>({
    step: 0,
    schoolName: '',
    schoolAdminEmail: '',
    schoolAdminCode: '',
  })
  const { step, schoolName, schoolAdminEmail, schoolAdminCode } = vars
  const [msg, setMsg] = useState<MessageProps>()
  const [keepSignedIn, setKeepSignedIn] = useState(false) // New state for checkbox
  const [timer, setTimer] = useState<number>(30)
  const [isResendEnabled, setIsResendEnabled] = useState<boolean>(false)

  // school state machine
  const onClose = () => navigate('/')
  const onSchool = async (sn: string = schoolName): Promise<any> => {
    const { msg, data, error } = await getSchool(sn)
    if (error || data?.status === 'missing') onError(msg)
    else {
      setVars({
        ...vars,
        schoolName: sn,
        step: 2,
      })
    }
  }
  const onSchoolEmail = async (sn: string = schoolName, sae: string = schoolAdminEmail): Promise<any> => {
    const { msg, error } = await verifySchoolAdminEmail(sn, sae)
    if (error) setMsg(msg)
    else {
      setVars({
        ...vars,
        schoolName: sn,
        schoolAdminEmail: sae,
        step: 3,
      })
      setIsResendEnabled(false)
      setTimer(30)
    }
  }
  const onSchoolAdmin = async (
    sn: string = schoolName,
    sae: string = schoolAdminEmail,
    sac: string = schoolAdminCode,
    adminSignInDurationSecs: any = keepSignedIn ? 21600 : undefined,
  ): Promise<any> => {
    // Verify User
    const { msg, error } = await verifySchoolAdminCode(sn, sae, sac, adminSignInDurationSecs)
    if (error) setMsg(msg)
    else {
      setItems(sn, sae, sac)
      props.onSignIn()
      navigate('/admin/settings')
    }
  }
  const onError = async (msg: any): Promise<any> => {
    clearItems()
    setMsg(msg)
    setVars({ ...vars, step: 4 })
  }

  useEffect(() => {
    let interval: any
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else {
      setIsResendEnabled(true)
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [timer])

  useEffect(() => {
    (async () => {
      const sn: string | null = searchParams.get('sn') ?? getItem('x-pool-sn')
      const sae: string | null = searchParams.get('sae') ?? getItem('x-pool-sae')
      const sac: string | null = searchParams.get('sac') ?? getItem('x-pool-sac')

      if (sn && sae && sac) await onSchoolAdmin(sn, sae, sac)
      else if (sn && sae) await onSchoolEmail(sn, sae)
      else if (sn) await onSchool(sn)
      else setVars({ ...vars, step: 1 })
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  switch (step) {
    case 1:
      return (
        <CenterComponent onClose={onClose} spacing={2}>
          <PaperFlowComponent
            title={'School Admin Sign-in'}
            subtitle={`Only authorized school staff or PTA members registered to administer the school can sign in.`}
          />
          <TypeaheadComponent
            onChange={async (v: any) => {
              if (!v || !v.communityName || !v.communityId) return
              let schoolId: string = `${v.communityName}-${v.communityId}`
              const { msg, data, error } = await getSchool(schoolId)
              if (error) setMsg(msg)
              else if (data?.status === 'missing') schoolId = v.communityName
              onSchool(schoolId)
            }}
          />
          {msg && (
            <ToastComponent
              style={msg?.style}
              heading={msg?.heading}
              text={msg?.text}
              onClose={() => setMsg(undefined)}
            />
          )}
        </CenterComponent>
      )
    case 2:
      return (
        <CenterComponent onClose={onClose} spacing={1}>
          <PaperFlowComponent
            title={'Enter Your School Email Address'}
            subtitle={`We'll send a one-time secure code to your registered school email address to verify your identity.`}
          />
          <InputComponent
            key='2b'
            type='text'
            label='Email Address'
            placeholder='example@schooldomain.edu'
            data_testid='admin-email'
            onChange={(v: string) => setVars({ ...vars, schoolAdminEmail: v.toLowerCase() })}
          />
          <ButtonComponent
            key='2c'
            text='Next'
            endIcon={<ArrowForwardIcon />}
            disable={!isValidEmail(schoolAdminEmail)}
            onClick={async () => await onSchoolEmail(schoolName, schoolAdminEmail)}
          />
          <Box pt={3}>
            <TextComponent size='body2'>
              By continuing, you have read and agree to our{' '}
              <span style={{ color: '#beac48', fontWeight: 'bold', textDecoration: 'underline' }}>
                <NavComponent
                  href={`${baseURL}/home/index.html#terms`}
                  text='Terms and Conditions'
                  isOpenNewTab={true}
                />
              </span>{' '}
              and{' '}
              <span style={{ color: '#beac48', fontWeight: 'bold', textDecoration: 'underline' }}>
                <NavComponent href={`${baseURL}/home/index.html#privacy`} text='Privacy Policy' isOpenNewTab={true} />
              </span>
              .
            </TextComponent>
          </Box>
          {msg && (
            <ToastComponent
              style={msg?.style}
              heading={msg?.heading}
              text={msg?.text}
              onClose={() => setMsg(undefined)}
            />
          )}
        </CenterComponent>
      )
    case 3:
      return (
        <CenterComponent onClose={onClose} spacing={2}>
          <PaperFlowComponent
            title={'Let’s confirm your email'}
            subtitle={
              <>
                To continue, enter the 6-digit verification code we sent to{' '}
                <span style={{ fontWeight: 'bold', color: '#000' }}>{schoolAdminEmail}</span>. Check junk mail if it’s
                not in your inbox.
              </>
            }
          />
          <VerificationCodeInput key='3b' onChange={(code: string) => setVars({ ...vars, schoolAdminCode: code })} />
          <Box
            sx={{ textAlign: 'left', paddingLeft: '10px', fontSize: '14px', display: 'flex', alignItems: 'flex-start' }}
          >
            <Checkbox
              size='small'
              color='warning'
              checked={keepSignedIn}
              onChange={() => setKeepSignedIn(!keepSignedIn)} // Update checkbox state
              data-testid='keep-signed-in-checkbox'
            />
            <Box sx={{ marginTop: '7px' }}>
              Keep me signed in
              <Box style={{ fontSize: '13px', marginTop: '4px' }}>
                This is for personal devices only. Don’t check this on shared devices to keep your account secure.
              </Box>
            </Box>
          </Box>

          <ButtonComponent
            key='3c'
            text='Next'
            endIcon={<ArrowForwardIcon />}
            disable={schoolAdminCode.length !== 6}
            onClick={async () => await onSchoolAdmin(schoolName, schoolAdminEmail, schoolAdminCode)}
          />

          <TextComponent size='body2'>Didn’t receive an email?</TextComponent>
          {isResendEnabled ? (
            <ButtonComponent
              key='3d'
              variant='text'
              color='warning'
              text='Resend another code'
              onClick={async () => {
                await onSchoolEmail(schoolName, schoolAdminEmail)
                setMsg({ style: 'success', text: 'A new code has been sent to your email.' })
              }}
            />
          ) : (
            <TextComponent size='body2'>You can request another code in {timer}s</TextComponent>
          )}

          {msg && (
            <ToastComponent
              style={msg?.style}
              heading={msg?.heading}
              text={msg?.text}
              onClose={() => setMsg(undefined)}
            />
          )}
        </CenterComponent>
      )
    default:
      return <></>
  }
}

export default Index
